import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { apiUrl,mediaUrl ,mediaUrlImage} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class Edit extends Component {

	constructor(props)
	{
		super(props);	
	    // const productId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		const productId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
		this.state = {
            productId: productId,
			product_name:'',
			price:'',
			// quantity:'',
			description:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			selectedCategory:'',
			categoryvalue:'',
			categorylist:[],
			image:'',
			image_name: '',
			image_preview: '',
			no_of_visite:'',
			selectedProductOption:{ value: '2', label: 'Shopping Product'},
			selectedproductvalue:'',
			brand_name:'',
			colornamelist:[],
			product_color:'',
			storenamelist:[],
			store_id: '',
			categorynamelist:[],
			brand_id:'',
			brandnamelist:[],
		};
		var qs = require('qs');
		var pid = this.state.productId;

		axios.get(apiUrl+"product/listdetail?id="+pid).then(res => {
			
			this.setState({ brand_name:res.data.productlist.brand_name, store_id :res.data.productlist.store_id, brand_id: res.data.productlist.brand_id,  product_color_id:res.data.productlist.product_color_id, image_name:res.data.productlist.image,product_name:res.data.productlist.name,price:res.data.productlist.price,
				description:res.data.productlist.description});
			if(res.data.productlist.product_type == 1){
				this.setState({selectedProductOption:{value: '1', label: 'Normal Product'}});
				$('.pricediv').hide();
			}else if(res.data.productlist.product_type == 2){
			this.setState({selectedProductOption:{value: '2', label: 'Shopping Product'}});
			 $('.pricediv').show();
			}else{
				this.setState({selectedProductOption:{value: '3', label: 'Regular Product'}});
				$('.pricediv').show();
			}
			if(res.data.productlist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
			if(res.data.selectedcategory!==null && res.data.selectedcategory.length > 0){
				if(res.data.selectedcategory[0].value!== '' && res.data.selectedcategory.value!== null){
					
					this.setState({ selectedCategory : res.data.selectedcategory[0]})
					// localStorage.setItem("original_selected_category",Props.productdetails[0].selectedcategory[0].value)
					}
			}
		
			if(res.data.productlist.no_of_visite!==null){
				this.setState({no_of_visite:res.data.productlist.no_of_visite});
			}
		}); 
		
		


		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		
		this.onChangeHandler = this.onChangeHandler.bind(this);
    }

	
	handleChangeCategory = selectedCategory => {

		this.setState({ selectedCategory});
		this.setState({ categoryvalue : selectedCategory.value });
 }


    handleChange = selectedOption => {

        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	handleProductChange = selectedProductOption => {
		this.setState({selectedProductOption})
		this.setState({  selectedproductvalue : selectedProductOption.value});
		var productType = selectedProductOption.value;
	
		if(productType==1){
			$('.pricediv').hide();
		}else{
			$('.pricediv').show();
		}
		
	}
	storelistloadedData(postobject=''){
		var admin_id = localStorage.getItem("admin_id");
		var accesscode = localStorage.getItem("company_code");  
	
	   axios.get(apiUrl+'product/getstorenamelist?admin_id='+admin_id+'&accesscode='+accesscode).then(res => {
		 if(res.data.status === 'success'){
			this.setState({  storenamelist: [{value: '', label: 'Select Store'}].concat(res.data.storelist)});
		 }else{
			  this.setState({  storelist: ''});
		 }
	   }); 
	  }
   handleInputChange(event) {
		const {name, value} = event.target;      
		this.setState({
          [name]: value
        });
	
		if(name ==='no_of_visite'){
			if(value<1){
				this.setState({no_of_visite:''});
			}
	   	}     	
    }
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file
		});
		
		};
		reader.readAsDataURL(file);
	}
	
	imageRemove = (productId) => {

		var postObject = {
			product_id:productId,
		};
		var qs = require('qs');

		axios.post(apiUrl+"product/deleteProductimage",qs.stringify(postObject) ).then(res => {
			if(res.data.status === "success"){	
				this.setState({image_name:res.data.productlist.image,product_name:res.data.productlist.name,price:res.data.productlist.price,
					description:res.data.productlist.description});
				if(res.data.productlist.status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
				}
				if(res.data.selectedcategory!==null && res.data.selectedcategory.length > 0){
					if(res.data.selectedcategory[0].value!== '' && res.data.selectedcategory.value!== null){
						
						this.setState({ selectedCategory : res.data.selectedcategory[0]})
						// localStorage.setItem("original_selected_category",Props.productdetails[0].selectedcategory[0].value)
						}
				}
	
				if(res.data.productlist.no_of_visite!==null){
					this.setState({no_of_visite:res.data.productlist.no_of_visite});
				}	
			}
		});
	}

	brandlistloadedData = (store_name) => {
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'product/getbradlist?admin_id='+admin_id+'&store_id='+store_name)
		
		.then(res => {
			if(res.data.status == 'success'){
			
				this.setState({
					brandnamelist: [{value: '', label: 'Select Category'}].concat(res.data.brandnamelist)
				});
			}else{
				
			}
		});
	}
	componentDidMount() {
		this.storelistloadedData();
		this.brandlistloadedData();
		document.title = 'product Edit';
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'product/getcolornamelist?admin_id='+admin_id)
			.then(res => {
				if(res.data.status == 'success'){
					this.setState({
						colornamelist: [{value: '', label: 'Select Color'}].concat(res.data.colornamelist)
					});
				}else{
				//console.log("test")
				}
			});
		
    }

	
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				var product_type ='';
				var price ='';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				var assigncategory;
				if(formPayload.selectedCategory !== ''){
				assigncategory =formPayload.selectedCategory.value;
				}else{
				assigncategory =formPayload.categroyvalue;
				}

		
				if(formPayload.selectedproductvalue === ''){
					product_type =formPayload.selectedProductOption.value;
					}else{
						product_type = formPayload.selectedproductvalue;
					}
				if(product_type ==1)
				{
					price ='';
				}else{
					price= formPayload.price;
				}

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					product_name : formPayload.product_name,
					price: price,					
					category_id: assigncategory,			
					description: formPayload.description,
					status:  status,
					id: formPayload.productId,
					image: formPayload.image,
					no_of_visite:formPayload.no_of_visite,
					product_type:product_type,
					product_color_id: this.state.product_color_id,
					store_id: this.state.store_id,
					brand_name: this.state.brand_name,
					brand_id: this.state.brand_id,
				};
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				axios.post(apiUrl+"product/edit",formData ).then(res => {
					if(res.data.status === "success"){	
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html('');
									this.props.navigate('/product')
								}
								.bind(this),
								3000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {
		const {product_name,
			price,selectedCategory,
			// quantity,
			description,doctor_visited,no_of_visite,selectedProductOption,selectedproductvalue ,brand_id} = this.state;
	
			let errors = {};
			  let formIsValid = true;
	
			if (!product_name) {
				formIsValid = false;
				$('.errorproduct_name').html('<span class="errorspan">Please fill the field</span>');
			}else if(product_name){
				$('.errorproduct_name').html('');
			}
			if (!brand_id) {
				formIsValid = false;
				$('.errorbrand_name').html('<span class="errorspan">Please fill the field</span>');
			}else if(brand_id){
				$('.errorbrand_name').html('');
			}
			if(!selectedCategory.value){
				formIsValid = false;
				$('.errorcat_name').html('<span class="errorspan">Please fill the field</span>');
			}else{
				$('.errorcat_name').html('');
			}
			if (!price) {
				formIsValid = false;
				$('.errorprice').html('<span class="errorspan">Please fill the field</span>');
			}else if(price){
				$('.errorprice').html('');
			}

			// if (!quantity) {
			// 	formIsValid = false;
			// 	$('.errorquantity').html('<span class="errorspan">Please fill the field</span>');
			// }else if(quantity){
			// 	$('.errorquantity').html('');
			// }

	

		this.setState({
			errors: errors
		});
		return formIsValid;
    }

	handleClickSelecetColor = (event) => {
		const selectedColorId = event.target.value;
		this.setState({ product_color_id: selectedColorId });
	  };
	  handleSelectBrand = (event) => {
		const selectedBrand = event.target.value;

		const selectedBrandObject = this.state.brandnamelist.find(
		  (brand) => brand.value === selectedBrand
		);
		this.setState({
			brand_name: selectedBrandObject.label,
			brand_id: selectedBrandObject.value,
		});

	  };
	  handleChangeStore = (event) => {
		const admin_id = localStorage.getItem("admin_id");
		const newStoreId = event.target.value;
		this.setState({ store_id: newStoreId });
		axios.get(apiUrl+'product/getcategorynamelist?admin_id='+admin_id+'&store_id='+newStoreId)	
		.then(res => {
			if(res.data.status == 'success'){
				
				this.setState({
					categorylist: [{value: '', label: 'Select Category'}].concat(res.data.categorynamelist)
				})	
				console.log(this.state.categorylist,'textcatory')
			}
		});
	};
  render() {
	const navigate = this.props.navigate;
	const {brand_id ,selectedCategory,selectedOption, categorylist ,selectedProductOption, selectedproductvalue ,colornamelist ,storenamelist ,store_id , brandnamelist } = this.state;
	var current = this;
	const categoryArray = this.state.categorylist.map ((categorylist, index) => ({
		id: index,
		name: categorylist,
		}));
		// Dynamically create select list
		let categorys = [];
		categoryArray.map(item =>
		categorys.push({ label: item.name.label, value: item.name.value }),
		);
		
	let loaderimg = '';
	let preview = '';

	  const imagepre = mediaUrlImage+'/'+this.state.image_name;
	
	  if (this.state.id!=='' && imagepre!== null && imagepre!== '') {
		  loaderimg = mediaUrlImage + '/'+this.state.image_name;
		 
		  preview = <img className="img_class" src={loaderimg} alt="" />;
		  console.log(preview,'productloadre')
	  }else{
		
		  preview = <img className="img_class" src={imagepre} alt="" />;
	  }

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="Product" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
            </div> 
			<div className="title">
				<h4>Product Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
					<div className="form-group">
					<label>Product Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="product_name" onChange={this.handleInputChange} className="form-control" value={this.state.product_name}/>
					<div className="errorproduct_name"></div>
					</div>
				
				<div className="form-group">					
					<label>Category<span class="required" style={{ color: "red" }} > * </span>: </label>
					<Select 
                        options={categorylist}  
                        value={selectedCategory ? selectedCategory : { value: '0', label: 'Select Category' }}
                        onChange={this.handleChangeCategory}
                        placeholder="Select Category"
                    />
					<div className="errorcat_name"></div>
				</div>
				<div className="form-group">					
					<label>Product Description:</label>
						<textarea type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.description} />
					<div className="errordescription"></div>
				</div>
				<div className="form-group">	
					<label>Color<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <select
						name="product_color"
						onChange={this.handleClickSelecetColor} // Update color selection handler
						value={this.state.product_color_id} // Set selected value based on state
          			>
					{this.state.colornamelist.map((colornamelist) => (
					<option
						key={colornamelist.value}
						value={colornamelist.value}
					>
						{colornamelist.label}
					</option>
					))}
				</select>
					<div className="errorproduct_color"></div>
				</div>
				
				<div className="form-group">
					<label>Brand Name<span className="required" style={{ color: "red" }} > * </span> : </label>
					<select name="store_name" onChange={this.handleSelectBrand} value={brand_id} >
						{brandnamelist.map((brand) => (
						<option
							key={brand.value}
							value={brand.value}
						>
							{brand.label}
						</option>
						))}
					</select>
					<div className="errorbrand_name"></div>			
					{/* <label>Product Type<span class="required" style={{ color: "red" }} > * </span> :</label>
						<Select 
							name="product_type"
							value={selectedProductOption?selectedProductOption:{ value: '2', label: 'Shopping Product'}}
							options={lang.common.productType_option} 
							isSearchable = {false}
							onChange={this.handleProductChange}
						/> */}
	           	</div>
			</div>
                  
			<div className="form-right">
				<div className="form-group">
					<label>Stores<span className="required" style={{ color: "red" }} > * </span> : </label>
					<select name="store_name" onChange={this.handleChangeStore} value={store_id} >
						{storenamelist.map((store) => (
						<option
							key={store.value}
							value={store.value}
						>
							{store.label}
						</option>
						))}
					</select>
					<div className="errorstore_name"></div>			
	           	</div> 
			<div className="form-group pricediv">
					<label>Price($)<span class="required " style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="price" onChange={this.handleInputChange} className="form-control" value={this.state.price}/>
					<div className="errorprice"></div>
				</div>
			   <div className="form-group">
					<label>Image:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{!this.state.image_preview && (
					<div className="form-group">
						{preview}
						{(this.state.image_name !== null && this.state.image_name !== '') && (
						<a href="javascript:void(0);" onClick={this.imageRemove.bind(this, this.state.productId)}>X</a>
						)}
					</div>
					)}
					<div className="errorloaderimage"></div> 
					{this.state.image_preview && (
						<div className="image-preview">
						<img src={this.state.image_preview} alt="Preview" className='img_class' />
						<a href="javascript:void(0);" onClick={this.imageRemove.bind(this, this.state.productId)}>X</a>
						</div>
					)} 
				</div> 
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
	           </div> 
				<div className="form-group doctor_visite_count">
					<label>No of Visit<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="no_of_visite" onChange={this.handleInputChange} className="form-control" value={this.state.no_of_visite}/>
					<div className="errorno_of_visite"></div>
				</div>
             

			</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}
export default (withRouter(Edit));