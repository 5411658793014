import React, { Component } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import moment from 'moment';
import {
    Row,
    Col,
    Modal,
    Table,
    OverlayTrigger,
    ButtonToolbar,
    Tooltip,
    Button
  } from "react-bootstrap"; 
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, mediaUrl} from'../Config/Config';
import { PageTitle } from "../Helpers/SettingHelper";

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
};

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const orderid = (typeof this.props.params.ID === 'undefined') ? '' : this.props.params.ID;

    this.state = {      
        orderid: orderid,
      productorderlistdata:'',
      productdetails:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :orderid
    };

    axios.post(apiUrl+"ProductHistory/view",qs.stringify(postObject)).then(res => {
      console.log(res);
      if(res.data.status === 'success'){
          this.setState({ productorderlistdata: res.data.order,productdetails:res.data.products});

      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('Category View');
   }
   productorderdetails()
   {
    var productdetails = this.state.productdetails;
    if (productdetails != "undefined" && productdetails != null) {
      if (productdetails.length > 0) {

        if (Object.keys(productdetails).length > 0) {
          const helperlistDetails = productdetails.map(
            (productdetails, Index) => {
              let sno = Index+1;
              let loaderimg = '';
              let preview = '';            
              var  imagepre = productdetails.image;      

              if (this.state.id!=='' && imagepre!== null && imagepre!== '') {
                loaderimg = mediaUrl +productdetails.image_path+productdetails.image;
                console.log(loaderimg,'configimages')
                preview = <img className="img_class  table-images" src={loaderimg} alt="" />;
              }else{
                // preview = <img className="img_class" src={noimage} alt="" />;
                preview = <img className="img_class table-images" src="" alt="" />;
              }

              return (
                <tr key={productdetails.id}>
      					<td>{sno}</td>
      					<td>{productdetails.product_name}</td>
      					<td>{preview}</td>
      					<td>{productdetails.catname}</td>
      					{/* <td>${productdetails.singleprice}</td> */}
      					{/* <td>{productdetails.quantity}</td> */}
      					<td>${productdetails.total_amount}</td>
      			    
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      }
    }
   }
   producttotalamount(){
    var productdetails = this.state.productdetails;
    if (productdetails != "undefined" && productdetails != null) {
      if (productdetails.length > 0) {
        let tot_amt = 0;
        if (Object.keys(productdetails).length > 0) {
           
          const helpertotamt = productdetails.map(
            (productdetails, Index) => {

                 tot_amt += parseInt(productdetails.price);
            }
          );
          return tot_amt;
        }
      }
    }
   }

  render() {
    const navigate = this.props.navigate;
  if(this.state.productorderlistdata!==undefined){
    var productorderlistdata = this.state.productorderlistdata;
  }
  

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="ProductHistory" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
               </div> 
                <div className="title">
                  <h3>Product Order History View</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>User Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productorderlistdata.username}</p>
                        </div>
                      </div>
                     {(productorderlistdata.email !=null && productorderlistdata.email!='')&& <div className="view-details">
                        <div className="view-details-one">
                            <p><b>User Email<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productorderlistdata.email}</p>
                        </div>
                      </div>}
                      {(productorderlistdata.mobileno !=null && productorderlistdata.mobileno!='')&&<div className="view-details">
                        <div className="view-details-one">
                            <p><b>User Mobile<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productorderlistdata.mobileno}</p>
                        </div>
                      </div>}
                      
                      {/* <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Specialist<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productorderlistdata.specialist_name}</p>
                        </div>
                      </div> */}

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Order ID<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productorderlistdata.order_id}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Transaction ID<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p> {productorderlistdata.stripe_transcation_id}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Paid Amount<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p> ${productorderlistdata.total_amount}</p>
                        </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Ordered On<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>  {moment(productorderlistdata.created_on).format('MMM DD Y hh:mm A')}</p>
                        </div>
                      </div>


                      


                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Order Detail </b></p>
                        </div>
                       
                      </div>
                      <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>product Name</th>
                  <th>Image</th>
			            <th>Category</th>
			            {/* <th>Price</th> */}
			            {/* <th>Quantity</th> */}
			            <th>Total Price</th>
                   </tr>
                  </thead>
                  <tbody>
                  {this.productorderdetails()}
                  </tbody>
                </Table>
			        	</div>
                <br></br>
                <div className="view-details-right ">
                        <div className="view-details-right-one">
                            <p><b>Paid Amount<span> : ${productorderlistdata.total_amount}</span> </b></p>
                        </div>
                      
                      </div>
                      
                      </div>
                    

                      </div>
                      </div>
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}


export default (withRouter(View));