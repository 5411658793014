import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { useParams, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap"; 

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl, carImageUrl, currency, dummycarUrl, mediaUrl} from'../Config/Config';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      configproductlist:'',
      domainlist:[],
      data_status:'',
      selectedStatus:'',
      selectid:'',
      data_status:'',
     
    };
    
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    componentDidMount() {
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");
      var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: admin_id,
      loggedas: loggedas
      };  

      axios.post(apiUrl+"configureproduct/configproducts_list",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){
          this.setState({  configproductlist: res.data.configproductlist, totalRecord: res.data.records_count,data_status: res.data.status}, function() {
            this.configproductlist();
          });
        }
      }); 
    }

    tablistload(postobject=''){
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");

      var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: admin_id,
        loggedas: loggedas
      };  

      axios.post(apiUrl+"configureproduct/configproducts_list",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){
         
            this.setState({  configproductlist: res.data.configproductlist, totalRecord: res.data.records_count,data_status: res.data.status}, function() {
            
              this.configproductlist();
            });
  
        }
      }); 
    }

    handlePageChange(pageNumber) {
      this.setState({activePage: pageNumber});

      var qs = require('qs');

      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");
      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id,
        loggedas:loggedas
      };
    
      axios.post(apiUrl+"configureproduct/configproducts_list",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){
          this.setState({  configproductlist: res.data.configproductlist, totalRecord: res.data.records_count,data_status: res.data.status}, function() {
            this.configproductlist();
          });
        }
      }); 
    }

    handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
        [name]: value
      });
      
    }

  handleFormSubmit = () => {
    this.setState({Loading:true});
    var qs = require('qs');
    const formPayload = this.state;
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
    var postObject = {             
      activePage   : 1,
      search_all : formPayload.search_all,
      admin_id: admin_id,
      loggedas: loggedas         
    };  

    axios.post(apiUrl+"configureproduct/configproducts_list",qs.stringify(postObject)).then(res => {
      if(res.data.status === 'success'){
          this.setState({  configproductlist: res.data.configproductlist, totalRecord: res.data.records_count,data_status: res.data.status}, function() {
            this.configproductlist();
          });
      }else if(res.data.status === 'error'){
        this.setState({  data_status: res.data.status}, function() {
          this.configproductlist();
        });
      }
    });
  }   

  componentWillReceiveProps(Props){
    if (Props.deletecolor!== this.props.deletecolor) {
      if (Props.deletecolor[0]["status"] === "success") {
         
        $('.success_message').html('<div class="status_sucess"><h3>'+ Props.deletecolor[0]["message"]+'</h3></div>');
        var qs = require('qs');
        var admin_id = localStorage.getItem("admin_id");
        var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: admin_id
        };

        axios.post(apiUrl+"configureproduct/list",qs.stringify(postobject)).then(res => {
          if(res.data.status === 'success'){
      
              this.setState({  configproductlist: res.data.configproductlist, totalRecord: res.data.records_count,data_status: res.data.status}, function() {
                this.configproductlist();
              });
    
          }
        });

        setTimeout(function(){
          $('.success_message').html('');
        }.bind(this),3000);

        this.setState({configproductlist: Props.deletecolor[0]["configproductlist"]}, function() {
          this.configproductlist();
        });       
      }    
    }

    if(Props.coloractive !== this.props.coloractive){
      if (Props.coloractive[0]["status"] === "success") {

        $('.success_message').html('<div class="status_sucess"><h3>'+ Props.coloractive[0]["message"]+'</h3></div>');
        var qs = require('qs');
        var admin_id = localStorage.getItem("admin_id");
        var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: admin_id
        };

        axios.post(apiUrl+"configureproduct/list",qs.stringify(postobject)).then(res => {
          if(res.data.status === 'success'){

              this.setState({  configproductlist: res.data.configproductlist, totalRecord: res.data.records_count,data_status: res.data.status}, function() {
                this.configproductlist();
              });

          }
        }); 

        setTimeout(function() {
          $('.success_message').html('');
        }.bind(this),3000);
      }
    }
  }
   
  confirmPopup = (id, status) => {
    var status = status;
    console.log(status,'statusnew')
      if(status == 'Inactive'){
        status = "Active";
      }else if(status == 'Active'){
        status = "Inactive";
      }else{
        status="Delete";
      }
    this.setState({selectid: id, selectedStatus: status})	
    $('.confirm-action').addClass('show');		
  }

  activateproduct(){
    let stat = ''
    if(this.state.selectedStatus ==='Inactive'){
      stat = "I";
    }else{
      stat = "A";
    } 
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      id  :this.state.selectid,
      status: stat
    };

    axios.post(apiUrl+"configureproduct/change_status",qs.stringify(postObject)).then(res => {
      if(res.data.status === 'success'){
      
        this.tablistload();
        $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
      
        setTimeout(
          function() {
          
            $('.success_message').html('');
              
          }
          .bind(this),
          3000
          );
      }
    }); 
  }

  deletetab(){
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      id  :this.state.selectid,
    };

    axios.post(apiUrl+"configureproduct/delete",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){  
      $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
        setTimeout(
          function() {
            $('.success_message').html('');
            
          }
          .bind(this),
          2000
          );     
          window.location.reload(); 
    }
    }); 
  }

  Confirmstatus =(confirmstatus)=>{
    console.log(this.state.selectedStatus,'confirmstatus')
    if(confirmstatus == 'yes'){
        if(this.state.selectedStatus=="Delete"){
          this.deletetab()
        }else{
          this.activateproduct();
        }
        $('.confirm-action').removeClass('show');
    }else{
      $('.confirm-action').removeClass('show');	
    }
  }

  configproductlist() {
    let catImg = '';
    let preview = '';
      var configproductlist = this.state.configproductlist;
      var data_status = this.state.data_status;
      if (configproductlist != "undefined" && configproductlist != null) {
        
        if (data_status == "success") {

          if (Object.keys(configproductlist).length > 0) {
            const listDetails = configproductlist.map(
              (configproductlist, Index) => {
                let sno = Index+1;
                var color_code;

                // if(configproductlist.status == 'A'){
                //   var status = 'Active';
                // }else{
                //     status = 'Inactive';
                // }
                //color_code = configproductlist.color_code;

                // var name =
                // configproductlist.product_name.charAt(0).toUpperCase()
                // + configproductlist.product_name.slice(1)
                return (
                  <tr key={configproductlist.id}>
                  <td>{sno}</td>
                  <td>{configproductlist.product_name}</td>
                  <td>{configproductlist.product_code}</td>
                  <td><img className="config-list" src={mediaUrl+configproductlist.config_image_path+configproductlist.config_image} width={200} height={200} alt="Main Image" /></td>
                  <td>${configproductlist.price}</td>
                  {/* <td>{status}</td> */}
    
                  <td class="actiongroup">
                      <Link to={{ pathname: '/config-product-view/'+configproductlist.id}} className="" title="View"> <i className="ft-eye" aria-hidden="true"></i>   </Link>
                      {/* <Link to={{ pathname: '/config-product-edit/'+configproductlist.id}} className="" title="Edit"> <i className="ft-edit-3" aria-hidden="true"></i></Link>   */}
                      <Link to={{ pathname: '/Config-product-addconfigitems/'+configproductlist.id}} className="" title="Edit"> <i class="fa fa-plus" aria-hidden="true"></i></Link>  
                      {/* {(status == 'Active'?<Link onClick={this.confirmPopup.bind(this, configproductlist.id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, configproductlist.id, status)} className="" title="Status"><i className="fa ft-lock" aria-hidden="true"></i></Link>)}  
                      <Link onClick={this.confirmPopup.bind(this,configproductlist.id)} className="" title="Duplicate"><i className="ft-trash-2" aria-hidden="true"></i></Link> */}
                    </td>
                  </tr>
                );
              }
            );
            return listDetails;
          }
        } else {
          return (
            <tr>
              <td colspan="11" className="v-align-nr">
                No Result
              </td>
            </tr>
          );
        }
      }else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  


  render() {

    const {domainlist,universitynamelist} = this.state;
	  var current = this;
    var admin_id = localStorage.getItem('admin_id');
    let catImg = '';
    let preview = '';
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="config-product-items" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>Configure Products Items</h3>
                </div>
               

               <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div> 
                 <div className="add_button">  
                    <a className="" href='/config-product-add'>Add Product</a>
                </div>
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>Product Name</th>
                  <th>Product Code</th>
                  <th>Image</th>
                  <th>Price</th>
                  {/* <th>Status</th> */}
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.configproductlist()}</tbody>
                </Table>
			        </div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      <div className="confirm-action">
          <p>Are you sure! you want to {this.state.selectedStatus} the product?</p>
          <a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
          <a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		  </div>
    </div>
    );
  }
}
export default (withRouter(List));