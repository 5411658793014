import React, { Component } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import moment from 'moment';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap"; 

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
        productbookinglist:'',
    };
    


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    componentDidMount() {
        var qs = require('qs');
        var admin_id = localStorage.getItem("admin_id");
        var loggedas = localStorage.getItem("loggedas");
        var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: admin_id,
        loggedas: loggedas
      };  

      axios.post(apiUrl+"ProductHistory/orderslist",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){
         
            this.setState({  productbookinglist: res.data.productbookinglist, totalRecord: res.data.records_count}, function() {
            
              this.productbookinglist();
            });
  
        }
      }); 
   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
    //  this.props.getproductlist(qs.stringify(postobject))
    axios.post(apiUrl+"ProductHistory/orderslist",qs.stringify(postobject)).then(res => {
      
      if(res.data.status === 'success'){
          this.setState({  productbookinglist: res.data.productbookinglist, totalRecord: res.data.records_count}, function() {
            this.productbookinglist();
          });

      }
    }); 

   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  axios.post(apiUrl+"ProductHistory/orderslist",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){
        this.setState({  productbookinglist: res.data.productbookinglist, totalRecord: res.data.records_count}, function() {
          this.productbookinglist();
        });
    }else{    
        this.setState({  productbookinglist: '', totalRecord: ''}, function() {
          this.productbookinglist();
        });
    }
  }); 

} 



   


productbookinglist() {
    var productbookinglist = this.state.productbookinglist;
    if (productbookinglist != "undefined" && productbookinglist != null) {
      if (productbookinglist.length > 0) {

        if (Object.keys(productbookinglist).length > 0) {
          const helperlistDetails = productbookinglist.map(
            (productbookinglist, Index) => {
              let sno = Index+1;

              return (
                <tr key={productbookinglist.id}>
      					<td >{sno}</td>
      					<td style={{textAlign: 'left'}}> {productbookinglist.username}</td>
      					<td style={{textAlign: 'left'}}>		{(productbookinglist.mobile!='')?productbookinglist.mobile:productbookinglist.email }  			</td>
      					<td style={{textAlign: 'left'}}>{productbookinglist.product_count}</td>
      					<td style={{textAlign: 'left'}}>${productbookinglist.total_amt}</td>
      					{/* <td style={{textAlign: 'left'}}>{productbookinglist.stripe_transcation_id}</td> */}
                <td style={{textAlign: 'left'}}>{moment(productbookinglist.created_on).format('MMM DD Y hh:mm A')}</td>
              
      					{/* <td>{productbookinglist.specialist_name}</td> */}
      					{/* <td>{productbookinglist.catname}</td> */}
      			    <td className="actiongroup" >
                     <Link  to={{ pathname: '/orders-view/'+productbookinglist.id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="8" className="v-align-nr" style={{textAlign:"center"}}>
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="8" className="v-align-nr" style={{textAlign:"center"}}>
            No Result
          </td>
        </tr>
      );
    }
  }
  


  render() {

    const {domainlist,universitynamelist} = this.state;
	  var current = this;
    var admin_id = localStorage.getItem('admin_id');
   let catImg = '';
  let preview = '';
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="orders" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>Order History</h3>
                </div>
               

                 <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                    <th>S.No</th>
                    <th>User Name</th>
                    <th>Contact</th>
                    <th>Purchased Item</th>
                    <th>Amount</th>
                    {/* <th>Transaction ID</th> */}
                    <th>Ordered On</th>
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.productbookinglist()}</tbody>
                </Table>
			        	</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}
export default (withRouter(List));