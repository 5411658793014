 /*local */ 
// export const apiUrl      = "http://localhost/lux/api/";
// export const baseUrl      = "http://localhost/lux";
// export const mediaUrl      = "http://localhost/lux/media/";
// export const mediaUrlImage      = "http://localhost/lux/media/product";
// export const sampleFileUrl      = "http://localhost/lux/media/sample_file/";
// export const cmscontentURL       = "http://localhost/lux/media/cmscontent";
// export const categoryURL       = "http://localhost/lux/media/category";
// export const productURL       = "http://localhost/lux/media/product";
// export const tapURL       = "http://localhost/lux/media/tabicons/";
// export const amenityURL       = "http://localhost/lux/media/amenities"; 

// /*Live */


export const apiUrl      = "https://admin.no1.luxury/luxury/api/";
export const baseUrl      = "https://admin.no1.luxury/luxury";
export const mediaUrl      = "https://admin.no1.luxury/luxury/media/";
export const mediaUrlImage      = "https://admin.no1.luxury/luxury/media/product";
export const sampleFileUrl      = "https://admin.no1.luxury/luxury/media/sample_file/";
export const cmscontentURL       = "https://admin.no1.luxury/luxury/media/cmscontent"; 
export const categoryURL       = "http://admin.no1.luxury/luxury/media/category";
export const  productURL       = "http://admin.no1.luxury/luxury/media/product";
export const amenityURL       = "http://admin.no1.luxury/luxury/media/amenities";
export const tapURL       = "http://admin.no1.luxury/luxury/media/tabicons";
export const cmsURL       = "http://admin.no1.luxury/luxury/media/cmsmanagement";