import React, { Component } from 'react';
import { lang } from'../Helpers/lang';
import { useParams, useNavigate } from 'react-router-dom';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Select from 'react-select';
import $ from 'jquery';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";

import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
import axios from 'axios';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Add extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
	      login_type:'',
	      show_count_yes:true,
	      show_count_no:false,
	      name:'',
	      module_type:'',
	      module_type_value:'',
	      priorty:'',
		  content:'',
		  image_preview:'',
		  image_name:'',
		  image:'',
	      name_arr:[],
	      module_type_arr:[],
	      module_type_value_arr:[],
	      priorty_arr:[],
		  content_arr:'',
		  image_preview_arr:'',
		  image_name_arr:'',
		  image_arr:'',
	      showlayout: false,
		  login_type:'',
	      select_options:'',
	      select_options_arr:[],
	      selectedvalue: '',
          selectedOption:{ value: 'active', label: 'Active'},
          Loading:false,
		  show_count_arr:[],
		  image_arr:[]
		};
    this.deletesinglefield = this.deletesinglefield.bind(this);
	this.onChangeHandler = this.onChangeHandler.bind(this);
 }
	
	componentDidMount() {
		  	if(localStorage.getItem("admin_id") === 'undefined' && localStorage.getItem('admin_id') === '' && localStorage.getItem('admin_id') === null){
			this.props.history.push('/');
		}
  }
	handleInputChange = (event) =>{
    const { name,value } = event.target;
    this.setState({[name]: value})
     if(name === 'show_count'){
       this.setState({show_count_yes: !this.state.show_count_yes});
       this.setState({show_count_no: !this.state.show_count_no});
    }

    if(name == 'select_options'){
        this.setState({select_options:value})
    }
  }

  onChangeHandler=event=>{
	let reader = new FileReader();
	const file = event.target.files[0];
	reader.onloadend = () => {
	this.setState({
		image_name: file.name,
		image_preview: reader.result,
		image: file
	});
	};
	reader.readAsDataURL(file);
}

     handleChange = login_type => {
        this.setState({login_type})
        this.setState({  login_type_value : login_type.value});
    };

 
 handleFormSubmit = () => {

				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
			
			
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					user: formPayload.login_type_value,
					name_arr: formPayload.name_arr,
					module_type_value_arr: formPayload.module_type_value_arr,
					priorty_arr: formPayload.priorty_arr,
					show_count_arr:formPayload.show_count_arr,
					content_arr: formPayload.content_arr,
					image_arr: formPayload.image_arr
				};
				//this.props.getAddForm(qs.stringify(postObject));
				
				let formData = new FormData();
				for(let k in postObject) {
					formData.append(k, postObject[k]);
				}
				axios.post(apiUrl+"dashboardmanagment/add",formData,config).then(res => {
					if(res.data.status === "success"){
							$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
									setTimeout(
									function() {
										$('.success_message').html(this.props.history.push('/dashboardmanagement'));
									}
									.bind(this),
									3000
									);
						}else{
							$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
							
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
						}
					});

	}

  handlefieldTypeChange(event) {   
      this.setState({module_type:[event],module_type_value:event.value})
  }

  Addtolayout=()=>{
  	if(this.validateFormfield()){
			var valuerequired;
			console.log(this.state.priorty_arr, this.state.show_count_arr)
			this.setState({
			name_arr: this.state.name_arr.concat(this.state.name), module_type_value_arr: this.state.module_type_value_arr.concat(this.state.module_type_value),priorty_arr: this.state.priorty_arr.concat(this.state.priorty),content_arr: this.state.content_arr.concat(this.state.content),image_arr: this.state.image_arr.concat(this.state.image)
			});
			if(this.state.show_count_yes == true){
				var show_count = 'yes';
			}else{
				var show_count = 'no';
			}
			this.setState({show_count_arr: this.state.show_count_arr.concat(show_count)})
			this.setState({showlayout: true},function(){
			this.setState({name:'',module_type:'',module_type_value:'',priorty:'',content:''});
			this.getformlayout();
			})
  	}
  }

  validateFormfield() {
		const {name,module_type_value,field_required,priorty,select_options} = this.state;
		let errors = 0;
	
		if (!name) {
			errors++;
			$('.errorname').html('<span class="errorspan">Please fill the field</span>');
		}else if(name){
			$('.errorname').html('');
		}


		if (!module_type_value) {
			errors++;
			$('.errormodule').html('<span class="errorspan">Please select Module</span>');
		}else if(module_type_value){
			$('.errormodule').html('');
		}

		if(!priorty){
		errors++;
			$('.errorpriority').html('<span class="errorspan">Please fill the field</span>');
		}else if(priorty){
			$('.errorpriority').html('');
		}

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

    }

       componentWillReceiveProps(Props){

    	 	if(Props.formadd !== this.props.formadd){
    		if(Object.keys(Props.formadd).length > 0){
				this.setState({Loading:false});

					if(Props.formadd[0].status === "success"){

							$('.success_message').html('<div class="status_sucess"><h3>'+Props.formadd[0].message+'</h3></div>');
							scrollToTop();
							setTimeout(
							function() {
							$('.success_message').html('');
							//this.handleReset();
							this.props.history.push('/formbuilder');
							}
							.bind(this),
							3000
							);
						}else{
							$('.success_message').html('<div class="status_sucess"><h3>'+Props.formadd[0].message+'</h3></div>');
							scrollToTop();
							setTimeout(
							function() {
							$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}

    		}
    	}

     }
  deletesinglefield(Index){
      let fieldname = [...this.state.name_arr];
      let fieldtype = [...this.state.module_type_value_arr];
      let fieldrequired = [...this.state.content_arr];
      let fieldpriorty = [...this.state.priorty_arr]; 
	  let show_count = [...this.state.show_count_arr]; 
      fieldname.splice(Index, 1);
      fieldtype.splice(Index, 1);
      fieldrequired.splice(Index, 1);
      fieldpriorty.splice(Index, 1);
	  show_count.splice(Index, 1);
      this.setState({name_arr: fieldname,module_type_value_arr: fieldtype,content_arr:fieldrequired,priorty_arr:fieldpriorty, show_count_arr: show_count});

  }
  getformlayout(){
      var form_field_value = this.state.name_arr;
      if (Object.keys(form_field_value).length > 0) {
      const formlayout = form_field_value.map(
      (datas, Index) => {
      let indexdata = Index+1;
      return (
            <div key={Index+'list'}>
            <li className="sortablecontainer-module-list">
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
            {datas}
            <div className="sortablecontainer-module-edit-button">
            {/*<button>Edit</button>*/}
            <button onClick={this.deletesinglefield.bind(this,Index)}>Delete</button>
            </div>
            </li></div> );

      }
      );
      return formlayout;
      }
  }
  getdeleteall = () =>{
      this.setState({showlayout:false,name_arr:[],module_type_arr:[],module_type_value_arr:[],priorty_arr:[],content_arr:[]});
  }

  render() {
  	  	const {selectedOption, priorty_arr, show_count_arr,login_type } = this.state;
			let preview = '';
  return (
   <div className="wrapper"> 
    <Header />
    <MenuSidebar />		
    	<div className="content">	
    		<div className="content-wrapper">
    			<div className="success_message"></div>
					<div className="title">
						<h4>Add</h4>
					</div>
    			<div className="content-body">
  			
        			<div className="builderpage-module-pagewrapper">
                 <section className="builderpage-module-section">
                    {(this.state.showlayout == true)?<> <h2 className="typography-module-title">Menu List</h2>
                       <div className="sortablecontainer-module-wrapper">
                         <ul>
                           {this.getformlayout()}
                         </ul>
                         <div className="sortablecontainer-module-delete-btn"><button onClick={this.getdeleteall}>Delete All</button></div>
                      </div></>:''}
                  </section>
            <form className="BuilderPage-module-form builderpage-module-section" onSubmit={ (e) => { e.preventDefault(); this.handleFormSubmit(this); }}>

                 {/*  <div className="form-group">
                  	<label>Login Type:</label>
                      <input type="text" className="form-control" placeholder="Login Type" name="login_type" value={this.state.login_type} onChange={this.handleInputChange} autoComplete="off"/>
					  
                	</div> */}
					<div className="form-group">					
					<label>Login Type:</label>
						   <Select 
						   value={login_type?login_type:{ value: 'User', label: 'User'}}
                            options={lang.common.login_type} 
                            onChange={this.handleChange}
                            />
				</div>
                	{/* <div className="form-group radio-check">
                	<label>Choose layout</label>
                        	<input className="form-check-input" type="radio" name="show_count" id="show_count_yes" checked/>
                        	<label className="form-check-label" for="show_count_yes">
                        	One Column
                        	</label>
                        	<input className="form-check-input" type="radio" name="show_count" id="show_count_no" />
                        	<label className="form-check-label" for="show_count_no">
                        	 Two Column
                        	</label>
                	</div> */}
                	{/* <div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div> */}
              <h2 className="typography-module-title">Menu</h2>
              	<div className="form-group">
              	<label>Name:</label>
                	<input type="text" name="name" className="form-control" value={this.state.name} placeholder="Name" onChange={this.handleInputChange} autoComplete="off" />
                <div className="errorname"></div>
              	</div>
                <div className="form-group">
                  <label>Module: </label>
                  <Select 
                  value={this.state.module_type!=''?this.state.module_type:'Choose'}
                  options={lang.common.field_options_dashboard} 
                  onChange={this.handlefieldTypeChange.bind(this)}
                  placeholder= 'Module'
                  isSearchable={false}
                  />
                    <div className="errormodule"></div>
                </div>
                {/* {(this.state.module_type_value == 'select' || this.state.module_type_value == 'checkbox' || this.state.module_type_value == 'radio')?<div className="form-group">
                <label>Options:</label>
                <input type="text" name="select_options" className="form-control" value={this.state.select_options} placeholder="Enter options separate by ," onChange={this.handleInputChange} autoComplete="off" />
                <div className="erroroptions"></div>
                </div>:''} */}
                <div className="form-group">
                        <label>Priority : </label>
                         <input type="text" name="priorty" className="form-control" value={this.state.priorty} placeholder=" Priority" onChange={this.handleInputChange} autoComplete="off" />
                         <div className="errorpriority"></div>
               </div>
			   <div className="form-group">
                        <label>Content : </label>
                         <input type="text" name="content" className="form-control" value={this.state.content} placeholder="Content" onChange={this.handleInputChange} autoComplete="off" />
               </div>
			 
			   <div className="form-group">
					<label>Image:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					{/* {loader_image_preview?<a href="javascript:void(0);">X</a>:''} */}
					<div className="errorloaderimage"></div>  
				</div> 
				<div className="form-group radio-check">
                	<label>Show Count</label>
                        	<input className="form-check-input" type="radio" name="show_count" id="show_count_yes" onChange={this.handleInputChange} checked/>
                        	<label className="form-check-label" for="show_count_yes">
                        	Yes
                        	</label>
                        	<input className="form-check-input" type="radio" name="show_count" onChange={this.handleInputChange}  id="show_count_no" />
                        	<label className="form-check-label" for="show_count_no">
                        	 No
                        	</label>
                	</div>
              <div className="submit-button">
                 <span className="btn btn_orange btn_minwid login_submit animate-btn2" onClick={this.Addtolayout}>Add</span>
              </div>
              <div className="">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
             </form>
          </div>
  			</div>	
  		</div>	
  	</div>
  </div>
  );
  }
  }


export default(withRouter(Add));