import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl,  mediaUrl } from '../Config/Config';
import axios from 'axios';
import { Scrollbars } from 'react-custom-scrollbars';

import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';




  const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
const initialState = {
			ad_name:'',
			ad_description:'',
			ad_short_desc:'',
			ad_link:'',			
			ad_button_text:'',
			module_type:'',
			module_type_value:'',	
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedsizevalue: '',
            selectedsizeOption:{ value: '350x350', label: '350x350'},
			user_image: [],
			vehicletype:[],
			user_image_name: [],
			user_image_preview: [],
			image: [],
			icon_preview : '',
			isHidden: false,
			statusmessage:'',
			vehiclevalue:'',
			Loading:false,
			fileName: [],
			thumbDefault: 0,
			priority: "",			
			selectedCateOption: '',
			selectedCatevalue:'',
			
			admin_id: localStorage.getItem('admin_id'),
			enableuploadgalley: false,
			imagelist:'',
			selectedimages:[],
			galleryimg: 'No',

			editid:0,
			image_preview:'',
			adscatelist:"",

			selectedLinkOption:"",
			selectedLinkvalue:"",
			cmslist:[],
			tablist:[],
			offer_page_type_value:""
		};
		
class Edit extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		//const adId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
		
		const adId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
		//this.state =  { ...initialState }
		this.state = {
			adId: adId,
			ad_name:'',
			ad_description:'',
			ad_short_desc:'',
			ad_link:'',			
			ad_button_text:'',			
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedsizevalue: '',
            selectedsizeOption:{ value: '350x350', label: '350x350'},
			user_image: [],
			vehicletype:[],
			user_image_name: [],
			user_image_preview: [],
			image: [],
			isHidden: false,
			statusmessage:'',
			vehiclevalue:'',
			Loading:false,
			fileName: [],
			thumbDefault: 0,
			priority: "",	
			additional_charges:"",		
			
			
			admin_id: localStorage.getItem('admin_id'),
			enableuploadgalley: false,
			imagelist:'',
			selectedimages:[],
			galleryimg: 'No'
		}
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   this.handleReset = this.handleReset.bind(this);
	   this.handlesizeChange = this.handlesizeChange.bind(this);
	   this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
	   this.onEditorChange = this.onEditorChange.bind( this );

    }
	getalloffers=()=>{
		var admin_id = localStorage.getItem('admin_id');
		
		return axios.get(`${apiUrl}cmsmanagement/getalloffers?admin_id=${admin_id}`)
		.then(res => {
			if (res.data.status === 'success') {
				this.setState({ cmslist: res.data.cmslist });
			}
		})
		.catch(error => {
			console.error("Error fetching offers:", error);
		});
	}
	listloaddata=()=>{

		var qs = require('qs');
		axios.get(apiUrl+"advertisement/listdetail?id="+this.state.adId).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.adslist;	
				this.setState({editid:1,image_preview:res.data.adslist.images,old_image:res.data.adslist.images})
					if(formdata.status === 'A'){
						this.setState({selectedOption:{value: 'active', label: 'Active'}});
					}else{
						this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
					}
					this.setState({  selectedCatevalue : formdata.advertisement_cate_id});
					if(formdata.module_name){
						
						this.setState({module_type : formdata.module_name});
					}
					if (formdata.link_type === 'offerpage') {
						this.setState({
							selectedLinkvalue: formdata.link_type,
							selectedLinkOption: [{ value: formdata.link_type, label: 'Offer Page' }]
						});
					} else if(formdata.link_type === 'internal'){
						this.setState({
							selectedLinkvalue: formdata.link_type,
							selectedLinkOption: [{ value: formdata.link_type, label: 'Internal Link' }]
						});
					}else if(formdata.link_type === 'external'){
						this.setState({
							selectedLinkvalue: formdata.link_type,
							selectedLinkOption: [{ value: formdata.link_type, label: 'External Link' }]
						});
					}
					this.getalloffers().then(() => {
						if (formdata.offer_page_id) {
							const offerpage = (this.state.cmslist || []).filter(item => item.value == formdata.offer_page_id);
							this.setState({
								offer_page_type: offerpage,
								offer_page_type_value: offerpage.length > 0 ? offerpage[0].value : null
							});
						}
					});		
					this.setState({ad_name:formdata.name,ad_description:formdata.description,ad_short_desc:formdata.short_desc,ad_link:formdata.link,adId: formdata.id,ad_button_text:formdata.button_text, old_tab_icon:formdata.images});					
			}	
			
			var postObject = {
				id: res.data.adslist.advertisement_cate_id,			
			};
			axios.post(apiUrl+"advertisement/catedetail",qs.stringify(postObject)).then(res => {
				if(res.data.status == "success"){					
					let catedetail = res.data.catedetail;
					this.setState({selectedCateOption:{value: catedetail['id'], label: catedetail['cate_name']}});					
				}
			});
			
		}); 
		
		
	}


	handleReset() {
         this.setState(initialState);
		 this.fileObj = [];
		 this.fileArray = [];
		 this.imageArray = [];
    }
	/*onEditorChange( evt ) {
	   	var callfunc = this;
	   	setTimeout(function () {
		this.setState( {
            template_content: evt
        } );
		}.bind(this),1000);
	
   	}*/

	   onEditorChange( evt ) {
    	
		localStorage.setItem('tab_content',evt.editor.getData())
     
    }

    handleChange = (r,selOption) => {
        //this.setState({selectedOption})
        //this.setState({  selectedvalue : selectedOption.value});
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'cate'){			
			this.setState({selectedCateOption:selOption})
			this.setState({  selectedCatevalue : selOption.value});
		}else if(r == 'inputtype'){		
			
			this.setState({selectedLinkOption:selOption})
			this.setState({  selectedLinkvalue : selOption.value});
			
		}
    };
	handlesizeChange = selectedsizeOption => {
        this.setState({selectedsizeOption})
        this.setState({  selectedsizevalue : selectedsizeOption.value});
		
    };
   handleInputChange(event) {

      const {name, value} = event.target;    
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Advertisement Edit');
		this.listloaddata();
		this.listloadadvertisementcategory();
		this.getalloffers();
    }

	listloadadvertisementcategory=()=>{
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'advertisement/adscatelist?admin_id='+admin_id)
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						adscatelist:res.data.adscatelist,
					});
				
				}else{
				}
			});
	}


onThumbChanged = (e) => {
    this.setState({
      thumbDefault: e.currentTarget.value
      });
  }
  
uploadMultipleFiles = (e) =>{

		this.fileObj = [];
        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
            //this.fileArray.push(this.fileObj[0][i])
            this.imageArray.push(this.fileObj[0][i])

        }
        this.setState({ user_image: this.imageArray, editid:0 });			
	
    }
	
	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var status, cate, modue_typeIs = '';

				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				if(formPayload.module_type_value === '' && formPayload.module_type_value!== 0){
					modue_typeIs =formPayload.module_type.value;
					}else{
						modue_typeIs = formPayload.module_type_value;
					}

				
				if(formPayload.selectedCatevalue === '' && formPayload.selectedCatevalue!== 0){
					cate =formPayload.selectedCateOption.value;
				}else{
					cate = formPayload.selectedCatevalue;
				}
				
				// var imgSize = '';
				// if(formPayload.selectedsizevalue === ''){
				// imgSize =formPayload.selectedsizeOption.value;
				// }else{
				// imgSize = formPayload.selectedsizevalue;
				// }
                
                var galleryimg = 'No';
				if(Object.keys(formPayload.selectedimages).length >0){
                   galleryimg = 'Yes';
				}

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					ad_name: formPayload.ad_name,
					ad_description:formPayload.ad_description,
					ad_short_desc:formPayload.ad_short_desc,
					ad_link:formPayload.ad_link,				
					ad_button_text:formPayload.ad_button_text,					
				//	ad_image        : formPayload.user_image,
					old_tab_icon : this.state.old_tab_icon,
					ad_status      :status,					
					modue_typeIs      :formPayload.module_type,	
					link_type      :formPayload.selectedLinkvalue,	
					//offer_pageid      :formPayload.offer_page_type_value,	
					...(formPayload.offer_page_type_value !== undefined && {
						offer_pageid: formPayload.offer_page_type_value,
					}),									
					id : formPayload.adId,
					cate_id:cate					
				};
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}
                
                var filesdata;
                if(galleryimg == 'No'){
                    filesdata = this.state.user_image;	
                }else{
                	 filesdata = this.state.selectedimages;	
                }
				
				
				// for (var i in filesdata) {
				//  formData.append('ad_image[]',filesdata[i])
				// }
				var icondata = formPayload.icon;
				formData.append('ad_image',icondata)	
							
			
				
				axios.post(apiUrl+"advertisement/edit",formData).then(res => {
				 
					if(res.data.status === 'success'){
					   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
					   setTimeout(
						function() {
							$('.success_message').html(this.props.navigate('/advertisement'));
						}
						.bind(this),
						3000
						);
						localStorage.removeItem('tab_content')
						 //  console.log('success edit')
					   }else{
						this.setState({Loading:false});
						   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   setTimeout(
						   function() {
							   $('.success_message').html('');
						   }
						   .bind(this),
						   3000
						   );
					   }
				  }); 
			}
	}

	validateForm() {
		const {ad_name,ad_link,user_image,selectedCateOption,module_type,ad_description} = this.state;
		
		let errors = 0;
	const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml'];
		let maxSize = 1000000;//1 mb
		if (!ad_name) {
			errors++;
			$('.errorad_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(ad_name){
			$('.errorad_name').html('');
		}

		if (!module_type) {
			errors++;
			$('.errormodule').html('<span class="errorspan">Please select Module</span>');
		}else if(module_type){
			$('.errormodule').html('');
		}


		if (!ad_description) {
			errors++;
			$('.errorad_description').html('<span class="errorspan">Please fill the field</span>');
		}else if(ad_description){
			$('.errorad_description').html('');
		}
	

		if(!selectedCateOption){
			errors++;
			$('.errorcate').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorcate').html('');
		}
	
		// if (!ad_short_desc) {
		// 	errors++;
		// 	$('.errorad_short_desc').html('<span class="errorspan">Please fill the field</span>');
		// }else if(ad_short_desc){
		// 	$('.errorad_short_desc').html('');
		// }
		

		/*if(this.state.editid == 1){
			
			$('.errorcarimage').html('');
		}
		else if(user_image.length == 0){
			errors++;
			$('.errorcarimage').html('<span class="errorspan">Please upload vehicle images</span>');
		}
		else if(user_image) {	 
			
	             for (let i = 0; i < user_image.length; i++) 
				 {

					const file = user_image[i];
					const filename = file.name;

					
					if (!allowedTypes.includes(file.type)) {
						errors++;
					
					$('.errorcarimage').html('<span class="errorspan"> '+filename+' file type is Invalid.</span>');

					continue;
					}
	            }
	       }*/


		if(errors>0) { //console.log('validation error')
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else { //console.log('validation error no')
		return true;
		}

    }

    
	removeImagebyindex = (indexvalue) => {
  	 
	  var list = [...this.imageArray];
	  this.imageArray = [];
	  this.fileArray = [];
	  list.splice(indexvalue, 1);
	 
	  for (let i = 0; i < list.length; i++) {
		this.imageArray.push(list[i]);
		this.fileArray.push(URL.createObjectURL(list[i]));
	  }
	
		this.setState({ user_image: this.imageArray })
		
		var thumbDefault = this.state.thumbDefault;
		if(indexvalue <= thumbDefault){
			let thum = thumbDefault-1;
			 if(thum < 0 ){
			   this.setState({thumbDefault : 0})
			 }else{
				this.setState({thumbDefault : thum});
			}    
		}
	}
	
	
	 getImagesFrontUpload(){
		 /*====Thumb image while choosing from front end===*/
		  if(Object.keys(this.fileArray).length > 0) {
			//var thumbDefaultArr = this.state.thumbDefault;
		 
			 const imgThumblist = this.fileArray.map((url, index) => {
						//var indicheckFlag = false;
												
						//if(Object.keys(thumbDefaultArr).length > 0){
							// if(thumbDefaultArr != null && thumbDefaultArr == index){
							// 	indicheckFlag = true;
							// }
						//}
						  	return (
							<li className="thumb" key={index}  >
								{/* <input type="radio" name="sel_img" id="sel_img"  value={index} onClick={this.onThumbChanged} checked={indicheckFlag} /> */}
								<span><img src={url}  alt="..." /></span>
								<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
							</li>
							);
			 });	
				return imgThumblist;					
			/*=======*/
		}
	 }

   //list selected image  from gallery
	 getafteruploaded(){
	 	var imgArray = this.state.selectedimages;
	 	if(Object.keys(imgArray).length > 0) {
		 	//var thumbDefaultArr = this.state.thumbDefault;
			 	const imgThumblist = imgArray.map((images, index) => {
			 	//console.log(images,'images')
			 			// var indicheckFlag = false;
		 				// if(thumbDefaultArr != null && thumbDefaultArr == index){
						// 	indicheckFlag = true;
						// } 
						return (
							<li className="thumb" key={index}  >
								{/* <input type="radio" name="sel_img" id="sel_img"  value={index} onClick={this.onThumbChanged} checked={indicheckFlag} /> */}
								<span><img src={mediaUrl+'advertisement/'+images}  alt="..." /></span>
								<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
							</li>
						);
			 });	
				return imgThumblist;					
			/*=======*/
		}

	 }
  //check gallery images
   Checkgallery = (indexs,value) =>{
      var imageArr = [...this.state.selectedimages];
      const index = imageArr.findIndex(images => images === indexs);
      if(index > -1) {
     	 imageArr = [...imageArr.slice(0, index), ...imageArr.slice(index + 1)]
      } else {
      imageArr.push(value);
      }
      this.setState({selectedimages: imageArr});
  }


 getGalleryimages() {
    var imageArr = this.state.imagelist;
    if(imageArr!== undefined && imageArr!== null){
      if(Object.keys(imageArr).length > 0) {
         const imageDetails = imageArr.map((image, Index) => {
       		var checked = false;
            return (
                        <div className="asp-col" key={Index}>   
                        <input type="checkbox" name="gallery_img" value={Index}  onClick={this.Checkgallery.bind(this,Index,image['name'])} />
                        <label><img src={image['url']} alt="" className="" /></label>
                        </div>
                     );
    
     });
      return imageDetails;
     }
    } else {
      return (<div className="">No images found</div>);
    }
  }

   galleryClose = () => {

   		if(Object.keys(this.state.selectedimages).length > 0){
   			 this.setState({galleryimg: 'Yes'});
   		}
   	    this.setState(prevState => ({
			enableuploadgalley: !prevState.enableuploadgalley
		}));
		$('.cargallery-popup').removeClass('active');
   }

   handlefieldTypeChange(event) {   
	this.setState({module_type:event.value,module_type_value:event.value})
}

   onChangeHandler=event=>{
	let reader = new FileReader();
	const file = event.target.files[0];
	const extension = file.name.split('.').pop();
	reader.onloadend = () => {
	  this.setState({
		icon_name: file.name,
		icon_preview: reader.result,
		icon: file,
		icon_fileExtension:extension,
		
	  },()=>{
		console.log(this.state.icon,'-icon')
	  });
	};
	reader.readAsDataURL(file);
	
  }
  handlefieldoffersTypeChange(event) {   
	this.setState({offer_page_type:[event],offer_page_type_value:event.value})
  } 
  render() {

	const selectedOptiondata = lang.common.field_options_ads.find(option => option.value == this.state.module_type);



	let imagesrc = '';
	
  	const {selectedOption,selectedCateOption, selectedLinkOption, user_image_preview} = this.state;
	  const navigate = this.props.navigate
	/*if(this.state.editid==1){
		var loaderimg = mediaUrl+'advertisement/' +this.state.image_preview;
		var preview = <img className="img_class" src={loaderimg} alt="" />;
	}else {		
		//var	preview = <img className="img_class" src={this.state.image_preview} alt="" />;
		var	preview = '';

	}*/

	var	preview = (this.state.icon_preview) ? 
		<img className="img_class" src={this.state.icon_preview} alt="" /> :
		<img className="img_class" src={mediaUrl+'/'+'advertisement/'+this.state.old_tab_icon} alt="" />
		;
	

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="advertisement" />  

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">
				<div class="content-wrapper-before"></div>
				<div className="form-wrapper leaa-admin-head card advertisement">
					<div className="content-wrapper">
						<a onClick={() => navigate(-1)}>Back</a>   
					</div>
						<div className="title">
							<h4>Edit Advertisement</h4>
						</div>
					<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
						<div className="form-row">
							<div className="form-left">

							<div className="form-group">					
						<label>Category<span class="required" style={{ color: "red" }} > * </span> :</label>
							<Select 
								value={selectedCateOption?selectedCateOption:""}
								options={this.state.adscatelist}  
								onChange={this.handleChange.bind(this, 'cate')}
								/>
								<div className="errorcate"></div>
							</div>

				
								<div className="form-group">
									<label>Name:</label>
									<input type="text" name="ad_name" onChange={this.handleInputChange} className="form-control" value={this.state.ad_name} />
									<div className="errorad_name"></div>
								</div>	
								<div className="form-group">					
									<label>Description:</label>
										
								<CKEditor
									editor={ ClassicEditor }
									data={this.state.ad_description}
									onReady={ editor => {
										// You can store the "editor" and use when it is needed.
									} }
									onChange={ ( event, editor ) => {
										const data = editor.getData();
										this.setState({ad_description: data});
										
									} }
								/>

									<div className="errorad_description"></div>
								</div>
								<div className="form-group">
									<label>Button Text:</label>
										<input type="text" name="ad_button_text" onChange={this.handleInputChange} className="form-control" value={this.state.ad_button_text} />
									<div className="errorad_button_text"></div>
								</div>
								
							</div>				
							<div className="form-right">
							<div className="form-group">
								<label>Link Type<span class="required" style={{ color: "red" }} >*</span></label>
										<Select
										value={selectedLinkOption?selectedLinkOption:""}
										options={lang.common.advertisement_linktype} 
										onChange={this.handleChange.bind(this, 'inputtype')}
										/>
								</div>
								{this.state.selectedLinkvalue=='offerpage'?
								<div className="form-group">
										<label>Offer Pages: </label>
										<Select 
											value={this.state.offer_page_type!=''?this.state.offer_page_type:'Choose'}
											options={this.state.cmslist} 
											onChange={this.handlefieldoffersTypeChange.bind(this)}
											placeholder= 'Select a Offer'
											isSearchable={false}
										/>
									<div className="errormodule"></div>
								</div>:''}
								{this.state.selectedLinkvalue=='internal'?
									<div className="form-group">
										<label>Module: </label>
										<Select 
											value={selectedOptiondata!=''?selectedOptiondata:'Choose'}
											options={lang.common.field_options_ads} 
											onChange={this.handlefieldTypeChange.bind(this)}
											placeholder= 'Module'
											isSearchable={false}
										/>
										<div className="errormodule"></div>
									</div> :''}

							{this.state.selectedLinkvalue=='external'?
								<div className="form-group">
									<label>Link Url: </label>
									<input type="text" name="ad_link" onChange={this.handleInputChange} className="form-group" value={this.state.ad_link} />
									<div className="errorad_link"></div>
									</div>
							:''}	
								<div className="form-group">
									<label>Short Description:</label>
										<input type="text" name="ad_short_desc" onChange={this.handleInputChange} className="form-control" value={this.state.ad_short_desc} autoComplete="off" />
									<div className="errorad_short_desc"></div>
								</div>					
					<div className="form-group">
						<label>Images (jpeg,jpg,png,svg)</label>
						<div className="choose-file">
						{this.state.isHidden && <div className="image_success"></div>}
						<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
						</div>
						{preview}
						
						<div className="errorimage"></div>  
					</div>

							

								<div className="form-group">					
									<label>Status:</label>
									<Select	value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
										options={lang.common.status_option} 
										onChange={this.handleChange.bind(this, 'status')}
									/>
								</div>
							</div>	
						</div>		
						<div className="btn-group export">	
							<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true && <span className="load-data"></span> }Submit</button>
						</div>
					</form>
			</div>
		</div>	
	</div>
	</div>	
    </div>
    );
  }
}


export default (withRouter(Edit));
