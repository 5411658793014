import React, { Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate } from "../Helpers/SettingHelper";
import { apiUrl} from'../Config/Config';
import axios from 'axios';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Add extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			coupon_code:'',
			discountType: { value: 'percentage', label: 'Percentage' },
			discountSel: '',
			discount:'',
			description: '',
			startDate: '',
			endDate: '',
			status:'',		    
			Loading:false,
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedDate:'',
		};
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   this.handleChange2 = this.handleChange2.bind(this);
    }
	

   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	handleChange = selectedOption => {
		this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	handleChange2 = discountType => {
		console.log(discountType,'this is use')
	   this.setState({discountType})
	   this.setState({  discountSel : discountType.value});
   };
   handleDateChange = (e) => {
	
	//this.setState({startDate:e.target.value})

	const {name, value} = e.target;      
	//console.log(name, value, "--handleDateChange");
      this.setState({
          [name]: value
        });
  };
	componentDidMount() {
		document.title = PageTitle('Coupon Add');
    }

  /*onFileChange = event => { 
     
      // Update the state 
      this.setState({ selectedFile: event.target.files[0] }); 
	  
	  this.fileObj = [];
        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
            //this.fileArray.push(this.fileObj[0][i])
            this.imageArray.push(this.fileObj[0][i])

        }
        this.setState({ font_file: this.imageArray })	
		
     
    }; */
	
	onFileChange = (e) =>{
	this.fileObj = [];
        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
            //this.fileArray.push(this.fileObj[0][i])
            this.imageArray.push(this.fileObj[0][i])

        }
        this.setState({ font_file: this.imageArray })		
    }

/*  onChangeHandler=event=>{
    let reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        user_image_name: file.name,
        user_image_preview: reader.result,
        image: file
      });
    };
    reader.readAsDataURL(file);
  }*/

	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				var discountType = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				if(formPayload.discountSel === '' && formPayload.discountSel!== 0){
					discountType =formPayload.discountType.value;
				}else{
					discountType = formPayload.discountSel;
				}
				var admin_id = localStorage.getItem("admin_id");
				var postObject = {
					admin_id     : admin_id,
					coupon_code    : formPayload.coupon_code,
					discount_type    : discountType,
					discount    : formPayload.discount,
					description : formPayload.description,
					startDate : formPayload.startDate,
					endDate : formPayload.endDate,
					status       : status,
				};

				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}
				
				// var filesdata = this.state.font_file;
				
				// for (var i in filesdata) {
				//  formData.append('font_file[]',filesdata[i])
				// }	
				
				
				axios.post(apiUrl+"coupon/add",formData,config).then(res => {
					
					if(res.data.status === "success"){
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');


								setTimeout(
								function() {
									$('.success_message').html('');
									this.props.navigate('/coupon');
								}
								.bind(this),
								3000
								);
					} else {
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}
				});
			}
	}

	validateForm() {

		const {coupon_code,discount_type,discount,description,startDate,endDate} = this.state;
		const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
		let errors = 0;
	
		if (!coupon_code) {
			errors++;
			$('.errorcoupon_code').html('<span class="errorspan">Please enter the code</span>');
		}else if(coupon_code){
			$('.errorcoupon_code').html('');
		}
		
		// if (!discount_type) {
		// 	errors++;
		// 	$('.errordiscount_type').html('<span class="errorspan">Please select discount type</span>');
		// }else if(discount_type){
		// 	$('.errordiscount_type').html('');
		// }
		if (!discount) {
			errors++;
			$('.errordiscount').html('<span class="errorspan">Please enter discount amount</span>');
		}else if(discount){
			$('.errordiscount').html('');
		}
		if (!description) {
			errors++;
			$('.errordescription').html('<span class="errorspan">Please enter description</span>');
		}else if(description){
			$('.errordescription').html('');
		}
		// if (!startDate) {
		// 	errors++;
		// 	$('.errorstartDate').html('<span class="errorspan">Please select start date</span>');
		// }else if(startDate){
		// 	$('.errorstartDate').html('');
		// }
		// if (!endDate) {
		// 	errors++;
		// 	$('.errorendDate').html('<span class="errorspan">Please select end date</span>');
		// }else if(endDate){
		// 	$('.errorendDate').html('');
		// }

		if (!startDate) {
			errors++;
			$('.errorstartDate').html('<span class="errorspan">Please select the field</span>');
		}else if(startDate){
			if (startDate >= today) {
				$('.errorstartDate').html('');
			}else{
				errors++;
				$('.errorstartDate').html('Please select a future date');
			}
		}
		if (!endDate) {
			errors++;
			$('.errorendDate').html('<span class="errorspan">Please select the field</span>');
		}else if(endDate){
			if (endDate >= startDate) {
				$('.errorendDate').html('');
			}else{
				errors++;
				$('.errorendDate').html('Please select a future date');
			}
		}

		

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    componentWillReceiveProps(Props){

      }

  render() {
  	const {selectedOption,discountType, startDate, endDate} = this.state;

	  const navigate = this.props.navigate;
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="coupon" />  

	<div className="content">	
		<div className="content-wrapper">
				  <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">

			<div className="success_message"></div>
			<div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
            </div>
			<div className="title">
				<h4>Add Coupon</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			   <div className="form-left">
		    	<div className="form-group">
					<label>Coupon Code<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="coupon_code" onChange={this.handleInputChange} className="form-control" value={this.state.coupon_code} autoComplete="off" />
					<div className="errorcoupon_code"></div>
				</div>
				<div className="form-group">
					<label>Discount Type<span class="required" style={{ color: "red" }} >*</span></label>
				   

					<Select 
					value={discountType?discountType:{ value: 'percentage', label: 'Percentage'}}
					options={lang.common.discount_option} 
					onChange={this.handleChange2}
					/>
					<div className="errordiscount_type"></div>
				</div>
				
				<div className="form-group">
					<label>Description</label>
					{/* <textarea className="form-control" name="description" onChange={this.handleInputChange}  value={this.state.description} id="" rows="10" cols="50"  autoComplete="off" /> */}
					<CKEditor
						editor={ ClassicEditor }
						data={this.state.description}
						onReady={ editor => {
							// You can store the "editor" and use when it is needed.
							console.log( 'Editor is ready to use!', editor );
							editor.ui.view.editable.element.style.minHeight = "300px";
						} }
						name="description"
						// onChange={this.handleInputChange}
						onChange={ ( event, editor ) => {
							const data = editor.getData();
							this.setState({description: data});
							editor.ui.view.editable.element.style.minHeight = "300px";
						} }
					/>
					<div className="errordescription"></div>
				</div>
				
				

				
				
			</div>				
			<div className="form-right">
				
				{/* <div className="form-group">
					<label>Start Date</label>					
					<input type="text" name="startDate" onChange={this.handleInputChange} className="form-control" value={this.state.startDate} autoComplete="off" />
					<div className="errorstartDate"></div>
				</div> */}
				<div className="form-group">
					<label>Valid From<span class="required" style={{ color: "red" }} >*</span></label>
				    <input
						type="date" className="form-control"
						id="datePicker"
						value={startDate}
						name="startDate"
						onChange={this.handleDateChange.bind(this)}
					/>
					<div className="errorstartDate"></div>
				</div>  
				{/* <div className="form-group">
					<label>End Date</label>					
					<input type="text" name="endDate" onChange={this.handleInputChange} className="form-control" value={this.state.endDate} autoComplete="off" />
					<div className="errorendDate"></div>
				</div> */}
				<div className="form-group">
					<label>Valid Till<span class="required" style={{ color: "red" }} >*</span></label>
				    <input
						type="date" className="form-control"
						id="datePicker1"
						value={endDate}
						name="endDate"
						onChange={this.handleDateChange.bind(this)}
					/>
					<div className="errorendDate"></div>
				</div>  
				<div className="form-group">
					<label>Discount Amount ($) <span class="required" style={{ color: "red" }} >*</span></label>					
					<input type="text" name="discount" onChange={this.handleInputChange} className="form-control" value={this.state.discount} autoComplete="off" />
					<div className="errordiscount"></div>
				</div>
				<div className="form-group">					
					<label>Status</label>
					<Select 
					value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
					options={lang.common.status_option} 
					onChange={this.handleChange}
					/>
				</div>

			 	{/* <div className="form-group" style={{display:"none"}}>
					<label>Font</label>
					<input type="file" onChange={this.onFileChange} multiple /> 
                	<div className="errorfile"></div> 
                </div> */}
				
			</div>
			</div>		

			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}



export default withRouter(Add);