import React from "react";
import $ from 'jquery';
import axios from "axios";
import Select from 'react-select';
import { lang } from '../Helpers/lang';

class Addimageinputsimple extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [
        {
          images: [],
          category_product_name : '',
          category_product_price: '',
          lappriority: '',
          category: '',
        }
      ],
      removekey: null, // Track the index of the row to be removed
      domain_id: null // Track the domain ID of the row to be removed
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleImageChange = (e, rowIndex) => {
    if (!e || !e.target || !e.target.files) {
      return;
    }

    const files = e.target.files;
    this.props.onChange(files);

    this.setState((prevState) => {
      const newRows = [...prevState.rows];
      newRows[rowIndex].images = [...files];
      return { rows: newRows };
    });
  }

  handleInputChange(event, rowIndex) {
    const { name, value } = event.target;
    this.setState((prevState) => {
      const newRows = [...prevState.rows];
      newRows[rowIndex][name] = value;
      return { rows: newRows };
    }, () => {
      console.log(this.state,'this is private')
      this.props.onChangeChild(this.state); // Send all values to the parent
    });
  }

  handleChange(selectedOption, rowIndex) {
    this.setState((prevState) => {
      const newRows = [...prevState.rows];
      newRows[rowIndex].selectedOption = selectedOption;
      newRows[rowIndex].laptype = selectedOption.value;
      return { rows: newRows };
    }, () => {
      this.props.onChangeChild(this.state); // Send all values to the parent
    });
  }

  addRow = () => {
    if (this.state.rows.length < 3) { // Maximum 3 rows
      this.setState((prevState) => ({
        rows: [...prevState.rows, { images: [], xaxis: '', yaxis: '', lappriority: '', category: '', laptype: 'overlap', selectedOption: { value: 'overlap', label: 'Overlap' } }]
      }));
    }
  }

  cancelConfirmPopup = (index, domainID) => {
    this.setState({ removekey: index, domain_id: domainID });
    $('.confirm-action').addClass('show');
  }

  cancelRideConfirm = (confirmstatus) => {
    if (confirmstatus === 'yes') {
      this.setState((prevState) => {
        const newRows = [...prevState.rows];
        newRows.splice(this.state.removekey, 1); // Remove the row at the specified index
        return { rows: newRows, removekey: null, domain_id: null };
      });
    }
    $('.confirm-action').removeClass('show');
  }

  render() {
    const showAddRowButton =  this.state.rows.length < 3;
   
    const showRemoveRowButton =  this.state.rows.length > 1;
    
    return (
      <>
        {this.state.rows.map((row, index) => (
          <div key={index} className="form-row">
            {/* {this.props.trialon === 'element' && ( */}
             
                <div className="form-left">
                <div className="form-group">
                    <label>Product Name:</label>
                    <input
                      type="text"
                      name='category_product_name'
                      value={row.category_product_name}
                      onChange={(e) => this.handleInputChange(e, index)}
                      required
                      onInvalid={(e) => e.target.setCustomValidity('Please enter a value for the category')}
                      onInput={(e) => e.target.setCustomValidity('')}
                    />
                  </div>
                  <label>Upload Product Image :</label>
                  <input type="file" className="form-control" name={`file-${index}`} onChange={(e) => this.handleImageChange(e, index)} />
                  <ul className="carimagelisting">
                    {row.images.map((image, i) => (
                      <li key={i} className="thumb">
                        <span>
                          <img src={URL.createObjectURL(image)} alt={image.name} />
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              <div className="form-right">
                  <div className="form-group">
                    <label>Price:($)</label>
                    <input
                      type="text"
                      name="category_product_price"
                      value={row.category_product_price}
                      onChange={(e) => this.handleInputChange(e, index)}
                      required
                      onInvalid={(e) => e.target.setCustomValidity('Please enter a value for the price')}
                      onInput={(e) => e.target.setCustomValidity('')}
                    />
                  </div>
                  <div className="form-right config-ringht">
                    {showRemoveRowButton && (
                      <button  onClick={this.cancelConfirmPopup.bind(this, index, row.domain_id)} type="button" >-</button>
                    )}
                    {showAddRowButton && (
                        <button onClick={this.addRow} type="button">+</button>
                    )}
                  </div>
                </div>
          </div>
        ))}
       
        <div className="confirm-action">
          <p>Do you want to remove your  Element? </p>
          <button onClick={this.cancelRideConfirm.bind(this, "yes")} className="btn">Yes</button>
          <button onClick={this.cancelRideConfirm.bind(this, "no")} className="btn">No</button>
        </div>
      </>
    );
  }
}

export default Addimageinputsimple;
