import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import './common/css/font-awesome.min.css';

import Adminprofile from './components/Account/Adminprofile';
import Login from './components/Account/Login';
import Logout from "./components/Account/Logout";
import Setting from "./components/Account/Setting";
import Changepassword from "./components/Account/Changepassword";
import Forgetpassword from "./components/Account/Forgetpassword";
import Resetpassword from "./components/Account/Resetpassword";
import Company from "./components/Company/List";
import CompanyAdd from "./components/Company/Add"
import CompanyEdit from "./components/Company/Edit";

import UserList from "./components/User/List";
import UserEdit from "./components/User/Edit";
import UserAdd from "./components/User/Add";
import UserView from "./components/User/View";

import StoreAdd from "./components/Store/Add";
import StoreList from "./components/Store/List";
import StoreEdit from "./components/Store/Edit";
//import StoreView from "./components/Store/View";

import ProductAdd from "./components/Product/Add";
import ProductList from "./components/Product/List";
import ProductEdit from "./components/Product/Edit";
import ProductView from "./components/Product/View";

import ProductCategoryAdd from "./components/ProductCategory/Add";
import ProductCategoryList from "./components/ProductCategory/List";
import ProductCategoryEdit from "./components/ProductCategory/Edit";
import ProductCategoryView from "./components/ProductCategory/View";

import ProductHistoryList from "./components/ProductHistory/List";
import ProductHistoryView from "./components/ProductHistory/View";

// import AppointmentList from "./components/Appointment/List";
// import AppointmentView from "./components/Appointment/View";
// import AppointmentAdd from "./components/Appointment/Add";
// import AppointmentAssigned from "./components/Appointment/AssignedList";
// import AppointmentObservation from "./components/Appointment/ObservationList";
// import AppointmentAssignedView from "./components/Appointment/AssignedView";
// import AppointmentObservationView from "./components/Appointment/ObservationView";

import MemList from "./components/Membership/List";
import MemEdit from "./components/Membership/Edit";
import MemAdd from "./components/Membership/Add";
import MemView from "./components/Membership/View";

import MembershipCategoryList from "./components/MembershipCategory/List";
import MembershipCategoryAdd from "./components/MembershipCategory/Add";
import MembershipCategoryEdit from "./components/MembershipCategory/Edit";

import Fontlist from "./components/Fonts/List";
import FontAdd from "./components/Fonts/Add";
import FontEdit from "./components/Fonts/Edit";

import TemplateList from './components/Templates/List';
import TemplateAdd from './components/Templates/Add';
import TemplateEdit from './components/Templates/Edit';

import DashboardList from "./components/Dashboard/List";
import Gallery from "./components/Gallery/Gallery";

import FaqCategoryList from "./components/FaqCategory/List";
import FaqCategoryAdd from "./components/FaqCategory/Add";
import FaqCategoryEdit from "./components/FaqCategory/Edit";

import FaqList from "./components/Faq/List";
import FaqAdd from "./components/Faq/Add";
import FaqEdit from "./components/Faq/Edit";
import FaqImport from "./components/Faq/ImportFaq";

import AmenityList from "./components/Amenities/List";
import AmenityAdd from "./components/Amenities/Add";
import AmenityEdit from "./components/Amenities/Edit";

import AiFeedList from "./components/AiFeed/List";
import AiImport from "./components/AiFeed/Import";

import TabList from "./components/TabManagement/List";
import TabEdit from "./components/TabManagement/Edit";
import TabAdd from "./components/TabManagement/Add";
import CompanyClone from "./components/Company/CompanyClone";

import ColorList from "./components/Color/List";
import ColorEdit from "./components/Color/Edit";
import ColorAdd from "./components/Color/Add";
import ColorView from "./components/Color/View";

import ConfigProductList from "./components/ConfigProductManagement/List";
import ConfigProductEdit from "./components/ConfigProductManagement/Edit";
import ConfigProductAdd from "./components/ConfigProductManagement/Add";
import ConfigProductView from "./components/ConfigProductManagement/View";

import ConfigProductAddconfig from "./components/ConfigProductManagement/Addconfig";

import BrandAdd from "./components/Brand/Add";
import BrandList from "./components/Brand/List";
import BrandEdit from "./components/Brand/Edit";
import BrandView from "./components/Brand/View";

import DashboardManagement from "./components/DashboardManagement/List";
import DashboardAdd from "./components/DashboardManagement/Add";
import DashboardEdit from "./components/DashboardManagement/Edit";


import OrdersList from "./components/Orders/List";
import OrdersView from "./components/Orders/View";




import DealerList from "./components/Dealer/List";
import DealerEdit from "./components/Dealer/Edit";
import DealerAdd from "./components/Dealer/Add";
import DealerView from "./components/Dealer/View";



import AdvertisementCategoryList from "./components/AdvertisementCategory/List";
import AdvertisementCategoryEdit from "./components/AdvertisementCategory/Edit";
import AdvertisementCategoryAdd from "./components/AdvertisementCategory/Add";



import AdvertisementList from "./components/Advertisement/List";
import AdvertisementEdit from "./components/Advertisement/Edit";
import AdvertisementAdd from "./components/Advertisement/Add";
import AdvertisementView from "./components/Advertisement/View";



import CouponList from "./components/Coupon/List";
import CouponEdit from "./components/Coupon/Edit";
import CouponAdd from "./components/Coupon/Add";
 import CouponView from "./components/Coupon/View";


import CMSList from "./components/CMS/List";
import CMSEdit from "./components/CMS/Edit";
import CMSAdd from "./components/CMS/Add";
import CMSView from "./components/CMS/View";


import './common/css/custom.css';


import ConfigProductitemsList from "./components/ConfigProductItems/List";
import ConfigProductitemsEdit from "./components/ConfigProductItems/Edit";
import ConfigProductAddconfigItems from "./components/ConfigProductItems/Addconfig";


import ConfigProductitemsView from "./components/ConfigProductItems/View";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/profile" element={< Adminprofile />} />
      <Route path="/logout" element={< Logout />} />
      <Route path="/setting" element={<Setting />} />
      <Route path="/changepassword" element={<Changepassword />} />
      <Route path="/forget_password" component={Forgetpassword} />
      <Route path="/reset_password/:key" component={Resetpassword} />
      <Route path="/company" element={<Company />} />
      <Route path="/company-add" element={< CompanyAdd />} />
      <Route path="/company-edit/:ID" element={<CompanyEdit/>} />
      <Route path="/font-edit/:ID" element={<FontEdit />} />
      <Route path="/fonts" element={<Fontlist />} />
      <Route path="/font-add" element={<FontAdd />} />

      <Route path="/user" element={<UserList/>} />
      <Route path="/user-add" element={< UserAdd/>} />
      <Route path="/user-edit/:ID" element={<UserEdit/>} />
      <Route path="/user-view/:ID" element={<UserView/>} />

      <Route path="/store" element={<StoreList/>} />
      <Route path="/store-add" element={<StoreAdd/>} />
      <Route path="/store-edit/:ID" element={<StoreEdit/>} />
      {/* <Route path="/store-view/:ID" element={<StoreView/>} /> */}
      <Route path="/gallery" element={<Gallery/>} />

      <Route path="/product" element={<ProductList/>} />
      <Route path="/product-add" element={<ProductAdd/>} />
      <Route path="/product-edit/:ID" element={<ProductEdit/>} />
      <Route path="/product-view/:ID" element={<ProductView/>} />

      <Route path="/productcategory" element={<ProductCategoryList/>} />
      <Route path="/productcategory-add" element={<ProductCategoryAdd/>} />
      <Route path="/productcategory-edit/:ID" element={<ProductCategoryEdit/>} />
      <Route path="/productcategory-view/:ID" element={<ProductCategoryView/>} />

      <Route path="/producthistory" element={<ProductHistoryList/>} />
      <Route path="/producthistory-view/:ID" element={<ProductHistoryView/>} />

      {/* <Route path="/appointments" element={<AppointmentList/>} />
      <Route path="/appointment-view/:ID" element={<AppointmentView/>} />
      <Route path="/appointment-add" element={<AppointmentAdd/>} />
      <Route path="/appointment-assigned" element={<AppointmentAssigned/>} />
      <Route path="/appointment-observation" element={<AppointmentObservation/>} />
      <Route path="/appointment-assignedview/:ID" element={<AppointmentAssignedView/>} />
      <Route path="/appointment-observationview/:ID" element={<AppointmentObservationView/>} /> */}

      
      <Route path="/membership" element={<MemList/>}  />
      <Route path="/membership-edit/:ID" element={<MemEdit/>} />
      <Route path="/membership-view/:ID" element={<MemView/>} />
      <Route path="/membership-add" element={<MemAdd/>} />

      <Route path="/membershipcategory" element={<MembershipCategoryList/>}  />
      <Route path="/membershipcategory-add" element={<MembershipCategoryAdd/>} />
      <Route path="/membershipcategory-edit/:ID" element={<MembershipCategoryEdit/>} />
      {/* <Route path="/user" element={UserList} />
      <Route path="/edit/:ID" element={UserEdit} />
      <Route path="/view/:ID" element={UserView} />
      <Route path="/user-add" element={UserAdd} />
      <Route path="/user-import" element={UserImport} /> */}

      <Route path="/templates" element={<TemplateList />} />
      <Route path="/template/add" element={<TemplateAdd/>} />
      <Route path="/templates-edit/:ID" element={<TemplateEdit />} />
      <Route path="/dashboard" element={<DashboardList />} /> 
      <Route path="/company-clone/:ID" element={<CompanyClone />} />
      <Route path="/faqcategory" element={<FaqCategoryList/>} />
      <Route path="/faqcategory-add" element={<FaqCategoryAdd/>} />
      <Route path="/faqcategory-edit/:ID" element={<FaqCategoryEdit/>} />  

      <Route path="/faq" element={<FaqList/>} />
      <Route path="/faq-add" element={<FaqAdd/>} />
      <Route path="/faq-edit/:ID" element={<FaqEdit/>} />
      <Route path="/faq-import" element={<FaqImport/>} />

      <Route path="/amenities" element={<AmenityList/>} />
      <Route path="/amenity-add" element={<AmenityAdd/>} />
      <Route path="/amenity-edit/:ID" element={<AmenityEdit/>} />
            
      <Route path="/aifeed" element={<AiFeedList/>} />
      <Route path="/aifeed-import" element={<AiImport/>} />
      <Route path="/tabmanagement" element={<TabList/>} />
        <Route path="/tabmanagement-edit/:ID" element={<TabEdit/>} />
        
        <Route path="/tabmanagement-add" element={<TabAdd/>} />
        <Route path="/color" element={<ColorList/>} />
      <Route path="/color-edit/:ID" element={<ColorEdit/>} />
      <Route path="/color-add" element={<ColorAdd/>} />
      <Route path="/color-view/:ID" element={<ColorView/>} />


      <Route path="/config-product-items" element={<ConfigProductitemsList/>} />
      <Route path="/Config-product-addconfigitems/:ID" element={<ConfigProductAddconfigItems/>} />

      <Route path="/config-product" element={<ConfigProductList/>} />
      <Route path="/config-product-edit/:ID" element={<ConfigProductEdit/>} />
      <Route path="/config-product-add" element={<ConfigProductAdd/>} />
      <Route path="/config-product-view/:ID" element={<ConfigProductView/>} />
      <Route path="/Config-product-addconfig/:ID" element={<ConfigProductAddconfig/>} />

      

      <Route path="/brand" element={<BrandList/>} />
      <Route path="/brand-add" element={<BrandAdd/>} />
      <Route path="/brand-edit/:ID" element={<BrandEdit/>} />
      <Route path="/brand-view/:ID" element={<BrandView/>} />


        <Route path="/dashboardmanagement" element={<DashboardManagement/>} />
        <Route path="/dashboardmanagement/edit/:ID" element={<DashboardEdit/>} />       
        <Route path="/dashboardmanagement-add" element={<DashboardAdd/>} />


        <Route path="/Orders" element={<OrdersList/>} />
        <Route path="/Orders-view/:ID" element={<OrdersView/>} />



      <Route path="/dealer" element={<DealerList/>} />
      <Route path="/dealer-add" element={< DealerAdd/>} />
      <Route path="/dealer-edit/:ID" element={<DealerEdit/>} />
      <Route path="/dealer-view/:ID" element={<DealerView/>} />
        
       <Route path="/advertisementcategory" element={<AdvertisementCategoryList/>} />
        <Route path="/advertisementcategory-edit/:ID" element={<AdvertisementCategoryEdit/>} /> 
        <Route path="/advertisementcategory-add" element={<AdvertisementCategoryAdd/>} />



        
        <Route path="/advertisement" element={<AdvertisementList/>} />
        <Route path="/advertisement-add" element={<AdvertisementAdd/>} />
        <Route path="/advertisement-edit/:ID" element={<AdvertisementEdit/>} />
        <Route path="/advertisement-view/:ID" element={<AdvertisementView/>} />



        <Route path="/cms" element={<CMSList/>} />
        <Route path="/cms-edit/:ID" element={<CMSEdit/>} />
        <Route path="/cms-add" element={<CMSAdd/>} /> 

        <Route path="/cms-view/:ID" element={<CMSView/>} />
          

        <Route path="/coupon" element={<CouponList/>} />
        <Route path="/coupon-edit/:ID" element={<CouponEdit/>} />
        <Route path="/coupon-add" element={<CouponAdd/>} /> 

        <Route path="/coupon-view/:ID" element={<CouponView/>} />

    </Routes>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
