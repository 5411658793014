import React, { Component } from 'react';
// import { withRouter } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import MyEditor from '../Membership/MyEditor';
import { useParams, useNavigate } from 'react-router-dom';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Addimageinputsimple from './Addimageinputsimple';
	
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Add extends Component {

	fileObj = [];
    fileArray = [];
    imageArray = [];
	fileObj1 = [];
	tryoutfileArray = [];
	tryimageArray = [];

	fileObj2 = [];
	configimageArray=[];
	configfileArray=[];
	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			name:'',
			product_code: '',
			price: '',
			qty:'',
			short_description: '',
			description: '',
			product_gallery_image: '',
			tryout_gallery_image: '',
			image_preview:'',
			image_name:'',
			image:'',
			fileExtension: '',
			tryout_image_preview:'',
			tryout_image_name:'',
			tryout_image:'',
			tryout_fileExtension: '',
			trialon: 'main',
			selectedOption:{ value: 'inactive', label: 'Inactive'},
			selectedvalue:'inactive',
			configtrialon:'main',
			rows: [], 
			config_gallery_image:'',
			selectedCateOption: '',
			quantity:"",
			cat:'',
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );
    }
	componentDidMount() {
		this.categoryListData()
    }
	  
	handleChange = selectedOption => {
        this.setState({selectedCateOption:selectedOption})
			this.setState({  selectedCatevalue : selectedOption.value});
   	};

	uploadMultipleFiles(arg,e){
		console.log(e,'addiaginput1')
		if (!e || !e.target || !e.target.files) {
			console.error("Invalid event or files not found.");
			return;
		  }
		
		if(arg=='product'){
			this.fileObj = [];
			this.fileObj.push(e.target.files)
			for (let i = 0; i < this.fileObj[0].length; i++) {
				this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
				this.imageArray.push(this.fileObj[0][i])
			}
			console.log(this.imageArray,"this.imageArray");
			this.setState({ product_gallery_image: this.imageArray })
		} else if (arg=='configtryout'){
			this.fileObj2 = [];
			this.fileObj2.push(e.target.files)
			console.log(this.fileObj2,'imagesttttttttt');
			for (let i = 0; i < this.fileObj2[0].length; i++) {
				
				this.configfileArray.push(URL.createObjectURL(this.fileObj2[0][i]))
				this.configimageArray.push(this.fileObj2[0][i])
				
				this.setState({ config_gallery_image: this.configimageArray })
				console.log(this.configimageArray,'images');
			}
		
		}
		
		// else{
		// 	this.fileObj1 = [];
		// 	this.fileObj1.push(e.target.files)
		// 	for (let i = 0; i < this.fileObj1[0].length; i++) {
		// 		this.tryoutfileArray.push(URL.createObjectURL(this.fileObj1[0][i]))
		// 		this.tryimageArray.push(this.fileObj1[0][i])
		// 	}
			
		// 	this.setState({ tryout_gallery_image: this.tryimageArray })
		// }	
	}
	uploadMultipleConfigFiles(arg,e){

		let tryimageArray= e;
		
		
		this.fileObj1 = [];
		
			this.fileObj1.push(tryimageArray)
			
			  for (let i = 0; i < this.fileObj1[0].length; i++) {
						this.tryoutfileArray.push(URL.createObjectURL(this.fileObj1[0][i]))
						this.tryimageArray.push(this.fileObj1[0][i])
			  }
					
			this.setState({ tryout_gallery_image: this.tryimageArray })
		  
			
		
		
	}
	

	removeImagebyindex = (indexvalue) => {
		var list = [...this.imageArray];
		this.imageArray = [];
		this.fileArray = [];
		list.splice(indexvalue, 1);
	
		for (let i = 0; i < list.length; i++) {
		this.imageArray.push(list[i]);
		this.fileArray.push(URL.createObjectURL(list[i]));
		}
	
		this.setState({ product_gallery_image: this.imageArray })
		
	}
	removeImageConfigbyindex = (indexvalue) => {
		var list = [...this.configimageArray];
		this.configimageArray = [];
		this.configfileArray = [];
		list.splice(indexvalue, 1);
	
		for (let i = 0; i < list.length; i++) {
		this.configimageArray.push(list[i]);
		this.configfileArray.push(URL.createObjectURL(list[i]));
		}
	
		this.setState({ config_gallery_image: this.imageArray })
		
	}
	removeTryoutImagebyindex = (indexvalue) => {
		var trylist = [...this.tryimageArray];
		this.tryimageArray = [];
		this.tryoutfileArray = [];
		trylist.splice(indexvalue, 1);

		for (let i = 0; i < trylist.length; i++) {
		this.tryimageArray.push(trylist[i]);
		this.tryoutfileArray.push(URL.createObjectURL(trylist[i]));
		}

		this.setState({ tryout_gallery_image: this.tryimageArray })
	}

  	onEditorChange( evt ) {
		console.log(evt, 'CKE')
		setTimeout(function () {
		this.setState( {
			short_description: evt
		} );
		}.bind(this),1000);
	}

	onSelcectImage(arg,event){
		console.log(arg,"argument")
		if(arg == 'product'){
			let reader = new FileReader();
			const file = event.target.files[0];
			const extension = file.name.split('.').pop();
			reader.onloadend = () => {
				this.setState({
				image_name: file.name,
				image_preview: reader.result,
				image: file,
				fileExtension:extension,
				
				});
			};
			reader.readAsDataURL(file);
		}else{
			let tryoutreader = new FileReader();
			const tryoutfile = event.target.files[0];
			const extension1 = tryoutfile.name.split('.').pop();
			tryoutreader.onloadend = () => {
				this.setState({
					config_gallery_image: tryoutfile.name,
					tryout_image_preview: tryoutreader.result,
					tryout_image: tryoutfile,
					tryout_fileExtension:extension1,
				});
			};
			tryoutreader.readAsDataURL(tryoutfile);
		}
	}

   	handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
  	}
	  handleRowChange = (rowIndex, updatedRow) => {
		
		this.setState((prevState) => {
			const updatedRows = [...prevState.rows];
			updatedRows[rowIndex] = updatedRow;
			return { rows: updatedRows };
		});
		
	}
  	handleSubmit = () => {
		if(this.validateForm()){
			const payload = this.state;
			var qs = require('qs');
			var status = '';
			var cate = '';

			const config = {
				headers: {
					'content-type': 'multipart/form-data'
				}
			}
			let formData = new FormData();
			var imagedata = payload.product_gallery_image;
			for (var i in imagedata) {
				formData.append('images[]',imagedata[i])
			}

			var configimagedata = payload.config_gallery_image;
			for (var i in configimagedata) {
				formData.append('configimages[]',configimagedata[i])
			}
			var tryimagedata = payload.tryout_gallery_image;
			for (var i in tryimagedata) {
				formData.append('tryoutimages[]',tryimagedata[i])
			}
			
			if(payload.selectedvalue === ''){
				status =payload.selectedOption.value;
			}else{
				status = payload.selectedvalue;
			}

			if(payload.selectedCatevalue === '' && payload.selectedCatevalue!== 0){
				cate =payload.selectedCateOption.value;
			}else{
				cate = payload.selectedCatevalue;
			}

			formData.append('admin_id',localStorage.getItem("admin_id"))
			formData.append('name',payload.name)
			formData.append('product_code',payload.product_code)
			formData.append('price',payload.price)
			formData.append('short_description',payload.short_description)
			formData.append('description',payload.description)
			formData.append('status','inactive')
			formData.append('image',payload.image)
			formData.append('cat_id',cate)
			formData.append('trialon',payload.trialon)
			formData.append('rowvalue',payload.rows)
			formData.append('quantity',payload.quantity)
			
			
			
		

			axios.post(apiUrl+"configureproduct/add",formData,config).then(res => {
				if(res.data.status === "success"){
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
							setTimeout(
							function() {
								this.props.navigate('/config-product');
							}
							.bind(this),
							3000
							);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
				}
			});
		}
		
	}

	validateForm() {
		const {name,product_code,price,image,product_gallery_image,tryout_image,tryout_gallery_image,qty} = this.state;
		const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml'];
		let errors = {};
      	let formIsValid = true;

		let maxSize = 9000000;//1 mb
		// if (!product_gallery_image) {
		// 	errors++;
		// 	$('.erroruser_image').html('<span class="errorspan">Please fill the field</span>');
		// }else if(product_gallery_image.length > 10){
				  
		// 	errors++;
		// 	$('.erroruser_image').html('<span class="errorspan">You can only upload up to 10 images</span>');
				   
		// }else{
		// 	for (let i = 0; i < product_gallery_image.length; i++) 
		// 	{
		// 		const file = product_gallery_image[i];
		// 		const filename = file.name;
		// 		console.log(filename,"filename")
		// 		if (file.size > maxSize) {
		// 			errors++;
		// 		$('.erroruser_image').html('<span class="errorspan">'+filename+'File is too large. Max size is 1MB</span>');
		// 		continue;
		// 		}
		// 		if (!allowedTypes.includes(file.type)) {
		// 			errors++;
		// 		$('.erroruser_image').html('<span class="errorspan"> '+filename+' file type is Invalid.</span>');
		// 		continue;
		// 		}
		// 	}
		// }

		// if (!tryout_gallery_image) {
		// 	errors++;
		// 	$('.errortryout_image').html('<span class="errorspan">Please fill the field</span>');
		// }else if(tryout_gallery_image.length > 10){
				  
		// 	errors++;
		// 	$('.errortryout_image').html('<span class="errorspan">You can only upload up to 10 images</span>');
				   
		// }else{
		// 	for (let i = 0; i < tryout_gallery_image.length; i++) 
		// 	{
		// 		const file1 = tryout_gallery_image[i];
		// 		const filename1 = file1.name;
		// 		console.log(filename1,"filename")
		// 		if (file1.size > maxSize) {
		// 			errors++;
		// 		$('.errortryout_image').html('<span class="errorspan">'+filename1+'File is too large. Max size is 1MB</span>');
		// 		continue;
		// 		}
		// 		if (!allowedTypes.includes(file1.type)) {
		// 			errors++;
		// 		$('.errortryout_image').html('<span class="errorspan"> '+filename1+' file type is Invalid.</span>');
		// 		continue;
		// 		}
		// 	}
		// }

      	if(!name){
        	formIsValid = false;
        	$('.errorname').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorname').html('');
		}

		if(!product_code){
        	formIsValid = false;
        	$('.errorproductcode').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorproductcode').html('');
		}

		if(!price){
        	formIsValid = false;
        	$('.errorprice').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorprice').html('');
		}

		if (!image) {
			formIsValid = false;
			$('.errorimage').html('<span class="errorspan">Please fill the field</span>');
		}else if(image){
			$('.errorimage').html('');
		}

		// if (!tryout_image) {
		// 	formIsValid = false;
		// 	$('.errortryoutimage').html('<span class="errorspan">Please fill the field</span>');
		// }else if(image){
		// 	$('.errortryoutimage').html('');
		// }

		// if(!qty){
        // 	formIsValid = false;
        // 	$('.errorqty').html('<span class="errorspan">Please fill the field</span>');
		// }else{
			
		// 	$('.errorqty').html('');
		// }
		
		this.setState({
			errors: errors
		});
		return formIsValid;
    }

	categoryListData(postobject=''){
		var qs = require('qs');
		var admin_id = localStorage.getItem("admin_id");
		var loggedas = localStorage.getItem("loggedas");
		var postobject = {
			activePage: 1,
			search_all: '',
			admin_id: admin_id,
			loggedas: loggedas
		};    

		axios.post(apiUrl+"ProductCategory/list",qs.stringify(postobject)).then(res => {
			if(res.data.status === 'success'){
				var categorylist = res.data.categorylist;
				let i=0;
				var categorylists = [];
				for(i; i<categorylist.length; i++){
					categorylists[i] = { value: categorylist[i]['id'], label: categorylist[i]['name'] };
					console.log(categorylist,"categorylists12");
				}
				console.log(categorylists,"categorylists");
				this.setState({  categorylist:categorylists });
			}
		}); 
	  }

  render() {

	const { selectedCateOption} = this.state;
	const navigate = this.props.navigate;
	let imageslist = [];
	let indexlength =  imageslist.length;

	const imgThumblist =  this.fileArray.map((url,index) => (
		<li className="thumb" key={indexlength+index}  >
			<span><img src={url}  alt="..." /></span>
			<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index,'prodcut')}>X</a>
		</li>
	));

	const imgtryoutThumblist = this.tryoutfileArray.map((url,index) => (
		<li className="thumb" key={index}  >
			<span><img src={url}  alt="..." /></span>
			<a href="javascript:void(0);" onClick={this.removeTryoutImagebyindex.bind(this,index,'tryout')}>X</a>
		</li>
	));

	var	preview = <img className="img_class" src={this.state.image_preview} alt="" />;
	// var	tryoutpreview = <img className="img_class" src={this.state.tryout_image_preview} alt="" />;

		{console.log(this.configfileArray,'tesintcofigfileArray')}
	const tryoutpreview =  this.configfileArray.map((url,index) => (
		<li className="thumb" key={indexlength+index}  >
			<span><img src={url}  alt="..." /></span>
			<a href="javascript:void(0);" onClick={this.removeImageConfigbyindex.bind(this,index,'configtryout')}>X</a>
		</li>
	));

	            // this.configfileArray.push(URL.createObjectURL(this.fileObj2[0][i]))
				// this.configimageArray.push(this.fileObj2[0][i])
    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="config-product" />  

		<div id="location-error"></div>
		<div className="content">	
			<div className="content-wrapper">
				<div class="content-wrapper-before"></div>
					<div className="form-wrapper leaa-admin-head card">
					<div className="success_message"></div>
					<div className="back-btn-lux">
						<a onClick={() => navigate(-1)}>Back</a>    
					</div>
					<div className="title">
						<h4>Add Configure Item</h4>				
					</div>
					<form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
						<div className="form-row">
							<div className="form-left">
								<div className="form-group">
									<label>Product Name<span class="required" style={{ color: "red" }} > * </span> : </label>
									<input type="text" name="name" onChange={this.handleInputChange} className="form-control" value={this.state.name}/>
									<div className="errorname"></div>
								</div>

								<div className="form-group">
									<label>Short Description<span class="required" style={{ color: "red" }} > </span></label>
									<MyEditor  onEditorChange={this.onEditorChange}></MyEditor>
								</div>

								<div className="form-group">
									<label> Market Price ( $ )<span class="required" style={{ color: "red" }} > * </span> : </label>
									<input type="number" name="price" onChange={this.handleInputChange} className="form-control" value={this.state.price}/>
									<div className="errorprice"></div>
								</div>

								<div className="form-group">
									<label>Category<span class="required" style={{ color: "red" }} > * </span> : </label>
									<Select 
									value={selectedCateOption?selectedCateOption:""}
										options={this.state.categorylist} 
										onChange={this.handleChange.bind(this)}
										/>
										<div className="errorcate"></div>
								</div>
								
								{/* <div className="form-group">
									<label>Trial On<span class="required" style={{ color: "red" }} >* </span></label>
									<input type="radio" value="main" name="trialon" className='trialon' checked={this.state.trialon === 'main'}  onChange={this.handleInputChange}  />Main Image

									<input type="radio" value="element" name="trialon" className='trialon' checked={this.state.trialon === 'element'}  onChange={this.handleInputChange}  />Element Image

									 <div className="errortrialon"></div>   
								</div> */}
								{/* <div className="form-group">
									<label>Configure Image<span class="required" style={{ color: "red" }} >* </span></label>
									<div className="choose-file">
									{this.state.isHidden && <div className="image_success"></div>}
									<span className="profile_btn">
										<input type="file" name="file" onChange={this.uploadMultipleFiles.bind(this,'configtryout')} multiple />
										</span>
									</div>
									<ul className="carimagelisting">
										{tryoutpreview}
									</ul>
									
								</div> */}
								{/* <div className="form-group"> 
									<div className="choose-file">
										{this.state.isHidden && <div className="image_success"></div>}
										<span className="profile_btn">
										<Addimageinputsimple
											onChange={(e) => this.uploadMultipleConfigFiles('tryout', e)}
									  	     imageList={imgtryoutThumblist}
											 trialon={this.state.trialon}
											 onChangeRow={this.handleRowChange}
									   	/>
										</span>
									</div>
									<div className="errortryout_image"></div>
								</div> */}
							</div>
							<div className="form-right">
								<div className="form-group">
									<label>Product Code<span class="required" style={{ color: "red" }} > * </span> : </label>
									<input type="text" name="product_code" onChange={this.handleInputChange} className="form-control" value={this.state.product_code}/>
									<div className="errorproductcode"></div>
								</div>
								{/* <div className="form-group">
									<label>Qty<span class="required" style={{ color: "red" }} > * </span> : </label>
									<input type="text" name="qty" onChange={this.handleInputChange} className="form-control" value={this.state.qty}/>
									<div className="errorqty"></div>
								</div> */}

								<div className="form-group">
									<label>Description<span class="required" style={{ color: "red" }} ></span></label>
									{/* <MyEditor  onEditorChange={this.onEditorChange}></MyEditor> */}
									<CKEditor
										editor={ ClassicEditor }
										data=""
										onReady={ editor => {
											// You can store the "editor" and use when it is needed.
											console.log( 'Editor is ready to use!', editor );
										} }
										onChange={ ( event, editor ) => {
											const data = editor.getData();
											this.setState({description: data});
											console.log( data,"data");
										} }
									/>
								</div>

								<div className="form-group">
									<label>Quantity<span class="required" style={{ color: "red" }} > * </span> : </label>
									<input type="number" name="quantity" onChange={this.handleInputChange} className="form-control" value={this.state.quantity}/>
									<div className="errorquantity"></div>
								</div>
								<div className="form-group">
									<label>Upload Image <span class="required" style={{ color: "red" }} >*</span>: (Allowed jpeg, png, svg)</label>
								</div>
								<div className="form-group">
									<div className="choose-file">
									{this.state.isHidden && <div className="image_success"></div>}
									<span className="profile_btn"><input type="file" name="file" onChange={this.onSelcectImage.bind(this,'product')} /></span>
									</div>
									{preview}
									<div className="errorimage"></div>  
								</div> 		
								
								<br></br><br></br><br></br>
								{/* <div className="form-group"> 
									<label>Main Thumbnail Image<span class="required" style={{ color: "red" }} ></span></label>
									<div className="choose-file">
										{this.state.isHidden && <div className="image_success"></div>}
										<span className="profile_btn">
										<input type="file" className="form-control" name="file" onChange={this.uploadMultipleFiles.bind(this,'product')} multiple /></span>
									</div>
									<ul className="carimagelisting">
										{imgThumblist}
									</ul>
									<div className="erroruser_image"></div>
								</div> */}

								

								{/* <div className="form-group">					
									<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
									<Select 
									name="status"
									value={selectedOption?selectedOption:{ value: 'inactive', label: 'Inactive'}}
									options={lang.common.status_option} 
									isSearchable = {false}
									onChange={this.handleChange}
									/>
									<div className="errorstatus"></div>
								</div> */}
							</div>	
							
						</div>		
						<div className="btn-group export">	
							<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >	Submit</button>
						</div>
					</form>
				</div>
			</div>	
		</div>
    </div>
    );
  }
}


export default (withRouter(Add));